import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { Header, Logo } from "src/components/Header/Header";
import { ProfilePicture } from "src/components/User/UserProfileHeader";
import { useCurrentUser } from "src/state/userQueries";
import { Autoplay, EffectCards } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export const DeviceMockup: React.FC<{ imageURL: string; light?: boolean }> = ({
  imageURL,
  light,
}) => {
  const color = light ? "#666" : "#222";
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      px="1em"
      mb={{ base: "-3.5em", md: "0em" }}
    >
      <Box
        maxW={{ base: "350px", lg: "400px" }}
        bg={color}
        borderRadius={{ base: "45px", md: "60px" }}
        position="relative"
        boxShadow="lg"
        borderWidth="12px"
        borderColor={color}
      >
        {/* iPhone pill */}
        <Box
          position="absolute"
          top="10px"
          left="50%"
          transform="translateX(-50%)"
          width="80px"
          height="20px"
          borderRadius="15px"
          bg={color}
        />

        {/* iPhone buttons (left side - volume) */}
        <Box
          position="absolute"
          left="-15px"
          top="100px"
          width="10px"
          height="35px"
          borderRadius="10px"
          bg={color}
        />
        <Box
          position="absolute"
          left="-15px"
          top="160px"
          width="10px"
          height="60px"
          borderRadius="10px"
          bg={color}
        />
        <Box
          position="absolute"
          left="-15px"
          top="230px"
          width="10px"
          height="60px"
          borderRadius="10px"
          bg={color}
        />

        {/* iPhone buttons (right side - power button) */}
        <Box
          position="absolute"
          right="-15px"
          top="175px"
          width="10px"
          height="80px"
          borderRadius="10px"
          bg={color}
        />

        {/* iPhone screen (where the screenshot will be displayed) */}
        <Image
          src={imageURL}
          alt="iPhone Screenshot"
          objectFit="cover"
          mx="auto"
          borderRadius={{ base: "35px", md: "50px" }}
          overflow="hidden"
        />
      </Box>
    </Flex>
  );
};

const HomeSection: React.FC<{
  title: string;
  subtitle: string;
  device: React.ReactNode;
  right?: boolean;
  showLogo?: boolean;
}> = ({ title, subtitle, device, right, showLogo }) => {
  const { data: user, isLoading, isError } = useCurrentUser();
  return (
    <Box
      width="100%"
      bg={right ? "#222" : undefined}
      className={right ? undefined : "bg-black"}
    >
      <Container maxW="1300px">
        <Flex
          height={{
            base: "auto",
            md: showLogo ? "calc(100vh + 20px)" : "100vh",
          }}
          direction={{ base: "column", md: right ? "row-reverse" : "row" }}
          align="center"
          width="100%"
          color={right ? "white" : "topFive.primary"}
          px="1em"
          overflow="hidden"
          mt={showLogo ? { base: "0px", md: "-75px" } : "0"}
        >
          <VStack
            spacing={8}
            align={{ base: "center", md: "start" }}
            w={{ base: "100%", md: "50%" }}
            py={{ base: "50px", md: "70px" }}
          >
            {showLogo && (
              <Logo
                color="original"
                height={{ base: "120px", md: "150px" }}
                linkDisabled
              />
            )}
            <Heading
              as="h2"
              size={{ base: "2xl", lg: "3xl" }}
              textAlign={{ base: "center", md: "start" }}
              color="white"
              lineHeight="1.5px"
            >
              {title}
            </Heading>
            <Text
              fontSize={{ base: "xl", md: "2xl" }}
              align="left"
              textAlign={{ base: "center", md: "start" }}
              color="white"
            >
              {subtitle}
            </Text>
            {isLoading ? (
              <Button
                variant="dark"
                borderRadius="full"
                px="2em"
                size="lg"
                fontWeight={600}
                leftIcon={<Spinner size="sm" />}
                disabled
              >
                Loading
              </Button>
            ) : !user || isError ? (
              <Button
                variant="dark"
                borderRadius="full"
                px="2em"
                size="lg"
                as={Link}
                fontWeight={600}
                to="/signup"
              >
                Sign Up
              </Button>
            ) : (
              <Button
                borderRadius="full"
                size="lg"
                as={Link}
                fontWeight={600}
                to="/me"
                leftIcon={
                  <ProfilePicture src={user.profilePicture} size={1.5} />
                }
              >
                Go to My Profile
              </Button>
            )}
          </VStack>
          <Box w={{ base: "100%", md: "50%" }}>{device}</Box>
        </Flex>
      </Container>
    </Box>
  );
};

const HomePage: React.FC = () => {
  return (
    <VStack width="100%" spacing={0} bg="black">
      <Header noLogo />

      <HomeSection
        title="Welcome to Podiums."
        subtitle="Show off your top songs, favorite movies, current shows, and more, all on one platform."
        device={
          <DeviceMockup imageURL="/screenshots/carter-profile-black.png" />
        }
        showLogo
      />
      <HomeSection
        title="Create lists in seconds"
        subtitle="Import songs & artists from Spotify, and create other lists with ease."
        device={<DeviceMockup imageURL="/screenshots/list.png" light />}
        right
      />
      <HomeSection
        title="Personalize your profile"
        subtitle="Choose a new background and layout to stand out from the crowd."
        device={
          <Box>
            <Swiper
              modules={[Autoplay, EffectCards]}
              speed={500}
              effect="cards"
              autoplay={{ delay: 2500, disableOnInteraction: false }}
              cardsEffect={{ slideShadows: false }}
              slidesPerView={1}
              style={{ overflow: "visible" }}
            >
              <SwiperSlide>
                <DeviceMockup imageURL="/screenshots/janelle-profile-sage.png" />
              </SwiperSlide>
              <SwiperSlide>
                <DeviceMockup imageURL="/screenshots/noah-profile.png" />
              </SwiperSlide>
              <SwiperSlide>
                <DeviceMockup imageURL="/screenshots/carter-profile-white.png" />
              </SwiperSlide>
            </Swiper>
          </Box>
        }
      />
      <HomeSection
        title="Share your link with the world"
        subtitle="Whether it's TikTok, Instagram, or Tinder, show your friends who you really are with your own personal link."
        device={<DeviceMockup imageURL="/screenshots/share.png" light />}
        right
      />
    </VStack>
  );
};

export default HomePage;
