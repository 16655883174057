import { Card, Center } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import {
  BACKDROP_BLUR,
  BACKGROUND_FADED,
  BLACK_AFTER,
  BLACK_BEFORE,
} from "src/constants";
import { UserTheme } from "src/types/userThemes";
import { Header } from "./Header/Header";
import { getListButtonBackground } from "./Lists/ListOfLists/ListButton";
import GradientBackground from "./User/UserBackground";

const PodiumsCard: React.FC<{ children?: ReactNode; [key: string]: any }> = ({
  children,
  ...rest
}) => {
  return (
    <Card
      p="1em"
      align="center"
      borderRadius="12px"
      width="100%"
      maxWidth="450px"
      bg={BACKGROUND_FADED}
      backdropFilter="auto"
      backdropBlur={BACKDROP_BLUR}
      boxShadow="md"
      {...rest}
    >
      {children}
    </Card>
  );
};

export const PodiumsThemedCard: React.FC<{
  children: ReactNode;
  theme: UserTheme;
  pastelKey?: string;
  [key: string]: any;
}> = ({ children, theme, pastelKey, ...rest }) => {
  return (
    <PodiumsCard
      bg={
        pastelKey === "white"
          ? "white"
          : getListButtonBackground(theme, undefined, pastelKey)
      }
      borderWidth={theme.cardBorderColor !== "none" ? "1px" : 0}
      borderColor={theme.cardBorderColor}
      boxSizing="border-box"
      overflow="hidden"
      boxShadow={theme.cardShadow}
      _before={theme.name === "Black" ? BLACK_BEFORE : undefined}
      _after={theme.name === "Black" ? BLACK_AFTER : undefined}
      {...rest}
    >
      {children}
    </PodiumsCard>
  );
};

export const PodiumsWrap: React.FC<{
  children: ReactNode;
  [key: string]: any;
}> = ({ children, ...rest }) => {
  return (
    <GradientBackground>
      <Header />
      <Center width="100%">
        <PodiumsCard p="2em" mx="1em" {...rest}>
          {children}
        </PodiumsCard>
      </Center>
    </GradientBackground>
  );
};

export default PodiumsCard;
