import { Box, HStack, VStack } from "@chakra-ui/react";
import {
  closestCorners,
  DndContext,
  DragEndEvent,
  DragOverEvent,
  KeyboardSensor,
  PointerSensor,
  useDroppable,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import React from "react";
import { UserList } from "src/types/list";
import { UserTheme } from "src/types/userThemes";
import SortableListPreviewButton from "./SortableListPreviewButton";

interface TwoColumnListOfListsProps {
  leftCol: UserList[];
  rightCol: UserList[];
  theme: UserTheme;
  setLeftCol: React.Dispatch<React.SetStateAction<UserList[]>>;
  setRightCol: React.Dispatch<React.SetStateAction<UserList[]>>;
}

const EditingTwoColumns: React.FC<TwoColumnListOfListsProps> = ({
  leftCol,
  rightCol,
  setLeftCol,
  setRightCol,
  theme,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const findContainer = (id: string): "left" | "right" | null => {
    if (leftCol.find((item) => item.slug === id)) return "left";
    if (rightCol.find((item) => item.slug === id)) return "right";
    return null;
  };

  const handleDragOver = (event: DragOverEvent) => {
    const { active, over } = event;
    if (!over) return;

    const id = active.id as string;
    const overId = over.id as string;

    // Handle dropping into empty columns
    if (overId === "left" || overId === "right") {
      const activeContainer = findContainer(id);
      if (!activeContainer || activeContainer === overId) return;

      const sourceItems = activeContainer === "left" ? leftCol : rightCol;
      const activeItem = sourceItems.find((item) => item.slug === id)!;

      if (activeContainer === "left") {
        setLeftCol((items) => items.filter((item) => item.slug !== id));
        setRightCol((items) => [...items, activeItem]);
      } else {
        setRightCol((items) => items.filter((item) => item.slug !== id));
        setLeftCol((items) => [...items, activeItem]);
      }
      return;
    }

    // Handle dropping onto other items
    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer === overContainer
    ) {
      return;
    }

    // Get the relevant arrays and items
    const sourceItems = activeContainer === "left" ? leftCol : rightCol;
    const targetItems = overContainer === "left" ? leftCol : rightCol;

    const activeItem = sourceItems.find((item) => item.slug === id)!;
    const overItemIndex = targetItems.findIndex((item) => item.slug === overId);

    // Update the state using functional updates
    if (activeContainer === "left") {
      setLeftCol((items) => items.filter((item) => item.slug !== id));
      setRightCol((items) => [
        ...items.slice(0, overItemIndex),
        activeItem,
        ...items.slice(overItemIndex),
      ]);
    } else {
      setRightCol((items) => items.filter((item) => item.slug !== id));
      setLeftCol((items) => [
        ...items.slice(0, overItemIndex),
        activeItem,
        ...items.slice(overItemIndex),
      ]);
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) return;

    const id = active.id as string;
    const overId = over.id as string;

    // Handle dropping into empty columns
    if (overId === "left" || overId === "right") {
      const activeContainer = findContainer(id);
      if (!activeContainer || activeContainer === overId) return;

      const sourceItems = activeContainer === "left" ? leftCol : rightCol;
      const activeItem = sourceItems.find((item) => item.slug === id)!;

      if (activeContainer === "left") {
        setLeftCol((items) => items.filter((item) => item.slug !== id));
        setRightCol((items) => [...items, activeItem]);
      } else {
        setRightCol((items) => items.filter((item) => item.slug !== id));
        setLeftCol((items) => [...items, activeItem]);
      }
      return;
    }

    // Handle reordering within the same column
    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer !== overContainer
    ) {
      return;
    }

    const items = activeContainer === "left" ? leftCol : rightCol;
    const activeIndex = items.findIndex((item) => item.slug === id);
    const overIndex = items.findIndex((item) => item.slug === overId);

    if (activeIndex !== overIndex) {
      if (activeContainer === "left") {
        setLeftCol((items) => arrayMove(items, activeIndex, overIndex));
      } else {
        setRightCol((items) => arrayMove(items, activeIndex, overIndex));
      }
    }
  };

  const onDelete = (listSlug: string) => {
    setLeftCol(leftCol.filter((l) => l.slug !== listSlug));
    setRightCol(rightCol.filter((l) => l.slug !== listSlug));
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
    >
      <HStack w="100%" align="start" spacing={2}>
        <Column id="left" items={leftCol} onDelete={onDelete} theme={theme} />
        <Column id="right" items={rightCol} onDelete={onDelete} theme={theme} />
      </HStack>
    </DndContext>
  );
};

const Column = ({
  id,
  items,
  onDelete,
  theme,
}: {
  id: string;
  items: UserList[];
  onDelete: (listSlug: string) => void;
  theme: UserTheme;
}) => {
  const { setNodeRef } = useDroppable({
    id,
  });

  return (
    <Box
      w="100%"
      minH="200px"
      bg="transparent"
      borderRadius="md"
      ref={setNodeRef}
    >
      <SortableContext
        items={items.map((item) => item.slug)}
        strategy={verticalListSortingStrategy}
      >
        <VStack w="100%" spacing={2}>
          {items.map((list) => (
            <SortableListPreviewButton
              key={list.slug}
              id={list.slug}
              list={list}
              onDelete={() => onDelete(list.slug)}
              theme={theme}
            />
          ))}
        </VStack>
      </SortableContext>
    </Box>
  );
};

export default EditingTwoColumns;
