import {
  Box,
  Center,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  UseDisclosureReturn,
  useToast,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa6";
import { ActionButton } from "src/components/Buttons";
import GradientBackground from "src/components/User/UserBackground";
import {
  CURRENT_USER_QUERY,
  populateListColumns,
  useReorderLists,
} from "src/state/userQueries";
import { UserList } from "src/types/list";
import { SanitizedOtherUser } from "src/types/user";
import { getTheme } from "src/types/userThemes";
import EditingOneColumn from "./EditingOneColumn";
import EditingTwoColumns from "./EditingTwoColumns";

const EditListsModal: React.FC<{
  user: SanitizedOtherUser;
  disclosure: UseDisclosureReturn;
}> = ({ user, disclosure: { isOpen, onClose } }) => {
  const [lists, setLists] = useState<UserList[]>(user.lists);
  const [leftColLists, setLeftColLists] = useState<UserList[]>(
    user.leftColLists ?? []
  );
  const [rightColLists, setRightColLists] = useState<UserList[]>(
    user.rightColLists ?? []
  );

  const reorderLists = useReorderLists();
  const toast = useToast();
  const queryClient = useQueryClient();
  const theme = getTheme(user.backgroundType);

  const onSaveLists = () => {
    if (user.listLayout.length === 1) {
      saveListsSingleColumn();
    } else {
      saveLists2Column();
    }
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    setLists(user.lists);
    setLeftColLists(user.leftColLists ?? []);
    setRightColLists(user.rightColLists ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isOpen]);

  const Inner = () => {
    if (user?.listLayout.length === 1) {
      return (
        <EditingOneColumn lists={lists} setLists={setLists} theme={theme} />
      );
    } else {
      return (
        <EditingTwoColumns
          theme={theme}
          leftCol={leftColLists}
          rightCol={rightColLists}
          setLeftCol={setLeftColLists}
          setRightCol={setRightColLists}
        />
      );
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        motionPreset="slideInBottom"
        isCentered={false}
        size="full"
      >
        <ModalOverlay bg="blackAlpha.200" backdropFilter="blur(1px)" />
        <ModalContent margin={0} bottom={0} p={0}>
          <ModalBody p={0}>
            <GradientBackground themeSlug={user.backgroundType}>
              <Box p="12px 12px 0px 12px">
                <HStack justify="space-between" mb="10px">
                  <Heading
                    as={"h2"}
                    fontSize="22px"
                    fontWeight={500}
                    color={theme.textOnBackgroundColor}
                  >
                    Edit Lists
                  </Heading>
                  <HStack spacing={4}>
                    <ModalCloseButton
                      size="lg"
                      pos="relative"
                      top={0}
                      boxSize="1em"
                      right={0}
                      padding={0}
                      _hover={{ bg: "none" }}
                      color={theme.textOnBackgroundColor}
                    />
                    <ActionButton
                      icon={FaCheck}
                      onClick={onSaveLists}
                      padding={2}
                      size="1.5em"
                      color={theme.textOnBackgroundColor}
                      borderWidth="2px"
                      borderColor={theme.textOnBackgroundColor}
                    />
                  </HStack>
                </HStack>
                <Center w="100%">
                  <Box maxW="450px" w="100%">
                    <Inner />
                  </Box>
                </Center>
              </Box>
            </GradientBackground>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );

  function saveLists2Column() {
    // check if lists were reordered
    if (
      leftColLists.every((l, i) => l.slug === user.listLayout[0][i]) &&
      rightColLists.every((l, i) => l.slug === user.listLayout[1][i]) &&
      leftColLists.length === user.listLayout[0].length &&
      rightColLists.length === user.listLayout[1].length
    ) {
      return;
    }
    toast.promise(
      reorderLists.mutateAsync({
        leftColListIDs: leftColLists.map((l) => l.slug),
        rightColListIDs: rightColLists.map((l) => l.slug),
      }),
      {
        success: (newU) => {
          queryClient.setQueryData(
            CURRENT_USER_QUERY,
            populateListColumns(newU)
          );
          return { title: "Lists saved!" };
        },
        loading: { title: "Loading..." },
        error(err) {
          return {
            title: "Error",
            description: err.message,
          };
        },
      }
    );
  }

  function saveListsSingleColumn() {
    // check if lists were reordered
    if (lists.every((l, i) => l.slug === user.listLayout[0][i])) {
      return;
    }
    toast.promise(
      reorderLists.mutateAsync({
        singleColListIDs: lists?.map((l) => l.slug),
      }),
      {
        success: (newU) => {
          queryClient.setQueryData(
            CURRENT_USER_QUERY,
            populateListColumns(newU)
          );
          return { title: "Lists saved!" };
        },
        loading: { title: "Loading..." },
        error(err) {
          return {
            title: "Error",
            description: err.message,
          };
        },
      }
    );
  }
};

export default EditListsModal;
