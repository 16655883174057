import {
  Box,
  Center,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React from "react";
import { FaTrashCan } from "react-icons/fa6";
import { ActionButton } from "src/components/Buttons";
import PodiumsCard from "src/components/PodiumsCard";
import { BACKDROP_BLUR, BLACK_AFTER, BLACK_BEFORE } from "src/constants";
import { useDeleteList } from "src/state/userQueries";
import { UserList } from "src/types/list";
import { UserTheme } from "src/types/userThemes";
import { DeleteListDialog } from "../Utils/ListDialogs";
import { getListButtonBackground } from "./ListButton";

interface SortableItemProps {
  id: string;
  list: UserList;
  onDelete: (id: string) => void;
  theme: UserTheme;
}

const SortableListPreviewButton: React.FC<SortableItemProps> = ({
  id,
  list,
  onDelete,
  theme,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const deleteDisclosure = useDisclosure();
  const deleteMutation = useDeleteList(list.slug);

  const handleDelete = async () => {
    await deleteMutation.mutateAsync();
    onDelete(list.slug);
  };

  return (
    <Center ref={setNodeRef} style={style} {...attributes} w="100%">
      <PodiumsCard
        bg={getListButtonBackground(theme, list)}
        py="10px"
        px="6px"
        w="100%"
        transform={isDragging ? "scale(1.05)" : "scale(1)"}
        boxShadow={isDragging ? "xl" : theme.cardShadow}
        backdropFilter="auto"
        backdropBlur={BACKDROP_BLUR}
        borderWidth={theme.cardBorderColor !== "none" ? "1px" : 0}
        borderColor={theme.cardBorderColor}
        boxSizing="border-box"
        overflow="hidden"
        _before={theme.name === "Black" ? BLACK_BEFORE : undefined}
        _after={theme.name === "Black" ? BLACK_AFTER : undefined}
      >
        <HStack w="100%">
          <VStack alignItems="center" mt="-8px">
            <ActionButton
              icon={FaTrashCan}
              fontSize="15px"
              width="auto"
              onClick={deleteDisclosure.onOpen}
              color={theme.textColor}
            />
            <Grid
              templateColumns="repeat(2, 1fr)"
              gap={1}
              cursor="grab"
              style={{
                touchAction: "none",
              }}
              {...listeners}
            >
              {[...Array(6)].map((_, index) => (
                <GridItem
                  key={index}
                  boxSize="4px"
                  bg={theme.textColor}
                  borderRadius="full"
                />
              ))}
            </Grid>
          </VStack>
          <VStack w="100%" spacing="0px">
            <Heading size="sm" color={theme.textColor}>
              {list.name}
            </Heading>
            <HStack mt="5px" spacing={0} mr="-10px">
              {list.items.map((item) => {
                return (
                  <Box
                    borderRadius="full"
                    borderWidth="3px"
                    borderColor="gray.100"
                    overflow="hidden"
                    boxSize="36px"
                    ml="-10px"
                  >
                    <Image
                      key={item._id}
                      src={item.imageURI}
                      boxSize="36px"
                      objectFit="cover"
                    />
                  </Box>
                );
              })}
            </HStack>
          </VStack>
        </HStack>
      </PodiumsCard>

      <DeleteListDialog
        deleteDisclosure={deleteDisclosure}
        onDelete={handleDelete}
        listName={list.name}
      />
    </Center>
  );
};
export default SortableListPreviewButton;
