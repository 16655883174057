// Frontend code using TypeScript and React with Chakra UI

import { Center, Heading, Spinner, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GradientBackground from "src/components/User/UserBackground";
import { useCurrentUser } from "src/state/userQueries";
import { LoginForm } from "../components/Auth/LoginForm";

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const { data: user, isLoading: isUserLoading } = useCurrentUser();

  const isUsernameSet = !user?.username.startsWith("!not-set-");

  useEffect(() => {
    const getUser = async () => {
      if (user && isUsernameSet) {
        // If the user is already logged in, redirect to the home page
        navigate("/me");
        return;
      }
    };
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Inner = () => {
    if (isUserLoading) {
      return (
        <VStack spacing={4} w="100%" maxW="450px">
          <Spinner size="xl" />
          <Heading>Loading...</Heading>
        </VStack>
      );
    }
    return <LoginForm />;
  };

  return (
    <GradientBackground>
      <Center width="100%" mx="auto" minH="90vh" px="1em" color="white">
        <Inner />
      </Center>
    </GradientBackground>
  );
};

export default LoginPage;
