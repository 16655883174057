import { Box, Circle, HStack, Icon, Link, Text } from "@chakra-ui/react";
import React from "react";
import { FaChevronLeft } from "react-icons/fa6";
import { Link as RouterLink } from "react-router-dom";

export interface ActionButtonProps {
  icon: any;
  color?: string;
  [key: string]: any;
}
export const ActionButton: React.FC<ActionButtonProps> = ({
  icon,
  color,
  ...rest
}) => {
  return (
    <Circle
      cursor="pointer"
      color="topFive.primary"
      aria-label="Edit List"
      size="2em"
      padding="0px"
      {...rest}
    >
      <Icon as={icon} style={{ color }} />
    </Circle>
  );
};

export const BackButton: React.FC<{
  to?: string;
  onClick?: (e?: any) => void;
  width?: string;
  text?: string;
  fontSize?: string;
  color?: string;
  [key: string]: any;
}> = ({ to, onClick, width, text, fontSize, color, ...rest }) => {
  const ButtonsAndText = () => (
    <HStack fontSize={fontSize ?? "1em"}>
      <Icon as={FaChevronLeft} color={color} />
      {text && <Text color={color}>{text}</Text>}
    </HStack>
  );
  return (
    <Box
      alignContent="start"
      width={width || "100%"}
      fontSize="lg"
      {...rest}
      transition="all 0.3s ease-in-out"
    >
      <Link
        as={to !== undefined ? RouterLink : undefined}
        to={to}
        onClick={onClick}
        borderBottom="1px solid white"
        _hover={{
          textDecoration: "none",
          borderBottom: "1px solid black",
        }}
      >
        <ButtonsAndText />
      </Link>
    </Box>
  );
};
