import {
  Box,
  Button,
  Center,
  Circle,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { FaCompactDisc, FaMusic, FaSpotify, FaUsers } from "react-icons/fa6";
import { TbShare2 } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { Header } from "src/components/Header/Header";
import PodiumsCard, { PodiumsThemedCard } from "src/components/PodiumsCard";
import { ThemeSelection } from "src/components/User/EditProfileModal";
import GradientBackground from "src/components/User/UserBackground";
import { useCurrentUser, useUpdateMe } from "src/state/userQueries";
import { getTheme, UserTheme } from "src/types/userThemes";
interface Step {
  title: string;
  description?: string;
  element: React.ReactNode;
  optional?: boolean;
  footer?: React.ReactNode;
}

const OnboardingPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [currentStep, setCurrentStep] = useState(0);
  const [userListsExist, setUserListsExist] = useState(false);
  const [theme, setTheme] = useState("black");
  const [isOneColumn, setIsOneColumn] = useState(true);

  const { data: currentUser } = useCurrentUser();
  const updateMe = useUpdateMe();

  useEffect(() => {
    if (!!currentUser) {
      setUserListsExist(currentUser.lists.length > 0);
    }
  }, [currentUser]);

  const nextStep = async () => {
    if (currentStep === steps.length - 1) {
      setLoading(true);
      await updateMe.mutateAsync({
        hasOnboarded: true,
        isOneColumn,
        backgroundType: theme,
      });
      setLoading(false);
      if (userListsExist) {
        console.log("User lists exist");
        navigate(`/${currentUser?.username}?spotify=true`);
      } else {
        console.log("User lists don't exist");
        navigate("/create/list");
      }
      // Finish
    }
    setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1));
  };
  const prevStep = () => setCurrentStep((prev) => Math.max(prev - 1, 0));

  const steps: Step[] = [
    {
      title: "",
      element: (
        <Center>
          <PodiumsCard
            boxShadow="none"
            as={motion.div}
            layoutId="profile-card"
            bg="none"
          >
            <VStack spacing={0}>
              <Heading fontSize="24px" fontWeight={500} color="gray.400">
                Welcome to
              </Heading>
              <Heading fontSize="60px" color="white">
                Podiums
              </Heading>
              <Heading size="lg" textAlign="center" mt="8px" color="white">
                Let's get started.
              </Heading>
            </VStack>
          </PodiumsCard>
        </Center>
      ),
    },
    {
      title: "Create lists",
      description:
        "Add up to 5 of your favorite songs, current shows, top artists, and more.",
      element: (
        <PodiumsCard
          as={motion.div}
          layoutId="profile-card"
          w="180px"
          mt="20px"
          boxShadow="lg"
        >
          <VStack spacing={2} align="stretch" w="100%">
            {[...Array(5)].map((_, index) => (
              <HStack>
                <Box bg="gray.200" w="40px" h="40px" borderRadius="md" />
                <VStack align="start" spacing={1}>
                  <Box bg="gray.200" w="60px" h="10px" borderRadius="md" />
                  <Box bg="gray.200" w="95px" h="10px" borderRadius="md" />
                </VStack>
              </HStack>
            ))}
          </VStack>
        </PodiumsCard>
      ),
    },
    {
      title: "Connect your Spotify",
      optional: true,
      description:
        "Import your top-listened songs, artists, and albums to quickly create your first few lists.",
      element: (
        <VStack>
          <PodiumsCard
            w="180px"
            as={motion.div}
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5 }}
            boxShadow="lg"
          >
            <VStack color="gray.500">
              <Icon as={FaSpotify} boxSize="34px" />
              <HStack>
                <Icon as={FaMusic} />
                <Icon as={FaCompactDisc} />
                <Icon as={FaUsers} />
              </HStack>
            </VStack>
          </PodiumsCard>
          <PodiumsCard
            as={motion.div}
            layoutId="profile-card"
            boxShadow="lg"
            w="180px"
          >
            <VStack spacing={2} align="stretch" w="100%">
              {[...Array(5)].map((_, index) => (
                <HStack key={index}>
                  <Box
                    bg="gray.200"
                    w="40px"
                    h="40px"
                    borderRadius="md"
                    as={motion.div}
                    layoutId={`image-${index}`}
                  />
                  <VStack align="start" spacing={1}>
                    <Box bg="gray.200" w="60px" h="10px" borderRadius="md" />
                    <Box bg="gray.200" w="95px" h="10px" borderRadius="md" />
                  </VStack>
                </HStack>
              ))}
            </VStack>
          </PodiumsCard>
        </VStack>
      ),
    },
    {
      title: "Share your personal link",
      description:
        "Whether it's TikTok, Instagram, or Tinder, show your friends who you really are.",
      element: (
        <Box
          w="100%"
          // bg="white"
          as={motion.div}
          layoutId="purple-bg"
          style={{
            maskImage: "linear-gradient(to bottom, transparent 0%, black 20%)",
          }}
          h="100%"
        >
          <Center
            w="100%"
            // className={`bg-party-gradient`}
            pt="50px"
            alignItems="start"
            h="100%"
          >
            <Box
              as={motion.div}
              layoutId="profile-card"
              bg="white"
              w="280px"
              h="60px"
              borderRadius="full"
              display="flex"
              alignItems="center"
              justifyContent="center"
              pos="relative"
              mt="70px"
            >
              <HStack spacing="2px">
                <Text fontSize="20px" fontWeight="bold" color="gray.300">
                  podiu.ms/
                </Text>
                <Text fontSize="20px" fontWeight="bold">
                  you
                </Text>
              </HStack>
              <Icon
                as={TbShare2}
                boxSize="26px"
                color="gray.300"
                pos="absolute"
                right="12px"
                top="16px"
              />
            </Box>
          </Center>
        </Box>
      ),
    },
    {
      title: "Choose your theme",
      element: <ProfilePreview themeSlug={theme} isOneColumn={isOneColumn} />,
      footer: (
        <VStack w="100%" spacing={4} my="10px">
          <ThemeSelection
            handleThemeChange={setTheme}
            curTheme={theme}
            isOneColumn={isOneColumn}
            textColor="white"
            simple
          />
          <Center w="100%">
            <HStack>
              <Text color="white" fontSize="md">
                Grid Mode
              </Text>
              <Switch
                onChange={(e) => setIsOneColumn(!e.target.checked)}
                isChecked={!isOneColumn}
                size="lg"
              />
            </HStack>
          </Center>
        </VStack>
      ),
    },
  ];

  return (
    <GradientBackground themeSlug="black">
      <Header />
      <Center width="100vw" overflow="hidden" position="relative" minH="70vh">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          maxW="450px"
          w="100%"
        >
          <VStack spacing={6} align="stretch" w="100%">
            <AnimatePresence mode="popLayout">
              <motion.div
                key={currentStep}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -50 }}
                transition={{ duration: 0.5 }}
              >
                <VStack spacing={4} align="stretch">
                  <Box
                    h="300px"
                    mb="-40px"
                    style={{
                      maskImage:
                        "linear-gradient(to bottom, black 60%, transparent 95%)",
                    }}
                    display="flex"
                    justifyContent="center"
                  >
                    {steps[currentStep].element}
                  </Box>
                </VStack>
              </motion.div>
            </AnimatePresence>

            {currentStep !== 0 && (
              <HStack justifyContent="center" mt={4}>
                {steps.slice(1).map((_, index) => (
                  <Box
                    key={index}
                    w={2}
                    h={2}
                    borderRadius="full"
                    bg={index + 1 === currentStep ? "blue.500" : "gray.200"}
                  />
                ))}
              </HStack>
            )}

            <Heading size="lg" textAlign="center" color="white">
              {steps[currentStep].title}
            </Heading>
            {steps[currentStep].optional && (
              <Heading
                size="20px"
                textAlign="center"
                mt="-20px"
                fontWeight={500}
                color="gray.500"
              >
                (optional)
              </Heading>
            )}
            <Center w="100%" display="flex" flexDir="column" gap={3}>
              {steps[currentStep].description && (
                <Text textAlign="center" color="gray.300" w="90%">
                  {steps[currentStep].description}
                </Text>
              )}

              {steps[currentStep].footer}

              <AnimatePresence mode="wait">
                <HStack
                  justifyContent={currentStep > 0 ? "space-between" : "center"}
                  w="100%"
                  as={motion.div}
                  layoutId="buttons"
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -50 }}
                  mt="1em"
                  px={3}
                >
                  {currentStep > 0 && (
                    <Button
                      onClick={prevStep}
                      isDisabled={currentStep === 0}
                      bg="none"
                      color="white"
                      cursor="pointer"
                      as={motion.div}
                      _hover={{ bg: "none", opacity: 0.8 }}
                    >
                      Previous
                    </Button>
                  )}
                  <Button
                    onClick={nextStep}
                    variant="dark"
                    isLoading={loading}
                    size={currentStep === 0 ? "lg" : "md"}
                  >
                    {currentStep === steps.length - 1 && !userListsExist
                      ? "Create a List"
                      : "Continue"}
                  </Button>
                </HStack>
              </AnimatePresence>
            </Center>
          </VStack>
        </Box>
      </Center>
    </GradientBackground>
  );
};

const ProfilePreview: React.FC<{ themeSlug: string; isOneColumn: boolean }> = ({
  themeSlug,
  isOneColumn,
}) => {
  const theme = getTheme(themeSlug);
  return (
    <Box
      bg="white"
      w="100%"
      style={{
        maskImage: "linear-gradient(to bottom, transparent 0%, black 20%)",
      }}
      as={motion.div}
      layoutId="purple-bg"
    >
      <VStack w="100%" pt="30px" className={`bg-${themeSlug}`} spacing={2}>
        <PodiumsThemedCard
          theme={theme}
          pastelKey={themeSlug === "pastel" ? "white" : undefined}
          w="180px"
          as={motion.div}
          layoutId="profile-card"
          boxShadow="lg"
          align="start"
          py="10px"
          px="8px"
        >
          <HStack>
            <Circle size="34px" bg="gray.300"></Circle>
            <VStack align="start" spacing={1}>
              <Box bg="gray.300" w="95px" h="10px" borderRadius="md" />
              <Box bg="gray.300" w="60px" h="10px" borderRadius="md" />
            </VStack>
          </HStack>
        </PodiumsThemedCard>
        {isOneColumn ? (
          [...Array(6)].map((_, index) => (
            <ListButtonPreview
              key={index}
              theme={theme}
              index={index}
              isSingleColumn
            />
          ))
        ) : (
          <HStack spacing={1} w="180px" alignItems="start">
            <VStack gap={1} w="100%" justifyContent="start">
              {[...Array(3)].map((_, index) => (
                <ListButtonPreview
                  key={index}
                  theme={theme}
                  index={index}
                  isSingleColumn={false}
                />
              ))}
            </VStack>
            <VStack gap={1} w="100%" justifyContent="start">
              {[...Array(3)].map((_, index) => (
                <ListButtonPreview
                  key={index}
                  theme={theme}
                  index={index + 3}
                  isSingleColumn={false}
                />
              ))}
            </VStack>
          </HStack>
        )}
      </VStack>
    </Box>
  );
};

const ListButtonPreview: React.FC<{
  theme: UserTheme;
  index: number;
  isSingleColumn?: boolean;
}> = ({ theme, index, isSingleColumn }) => {
  return (
    <PodiumsThemedCard
      theme={theme}
      pastelKey={(index * 6).toString()}
      as={motion.div}
      layoutId={index === 0 ? "main-card" : `list-${index}`}
      w={isSingleColumn ? "180px" : "90px"}
      boxShadow="lg"
      p="8px"
    >
      <VStack align="start" spacing="5px" w="100%">
        <Box bg="gray.300" w="50px" h="10px" borderRadius="md" />
        <Grid
          w="100%"
          gridTemplateColumns={`repeat(${isSingleColumn ? 5 : 2}, 1fr)`}
          gap="5px"
        >
          {[...Array(isSingleColumn ? 5 : 4)].map((_, itemIndex) => (
            <GridItem
              key={itemIndex}
              bg="gray.300"
              h={index % 2 === 1 ? "50px" : isSingleColumn ? "30px" : "35px"}
              w={isSingleColumn ? "100%" : "35px"}
              borderRadius="md"
              as={index === 0 ? motion.div : undefined}
              layoutId={index === 0 ? `image-${itemIndex}` : undefined}
            />
          ))}
        </Grid>
      </VStack>
    </PodiumsThemedCard>
  );
};

export default OnboardingPage;