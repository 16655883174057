import {
  Box,
  Heading,
  HStack,
  Icon,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";
import {
  FaChevronRight,
  FaEyeSlash,
  FaRegGem,
  FaSpotify,
} from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import PodiumsCard from "src/components/PodiumsCard";
import {
  BACKDROP_BLUR,
  BACKGROUND_DARK_GRADIENT_COLORED,
  BACKGROUND_HIDDEN,
  BLACK_AFTER,
  BLACK_BEFORE,
  borderRadiusDisabledUsers,
  IMAGE_PADDING_LISTS,
} from "src/constants";
import { useCurrentUser } from "src/state/userQueries";
import { UserList } from "src/types/list";
import { UserTheme } from "src/types/userThemes";
import { stringToPastelColor } from "src/utils";
import { useDeleteListToast } from "../Edit/EditList";
import { DeleteListDialog, SpotifyDialog } from "../Utils/ListDialogs";
import ListGrid from "../View/ListGrid";

const ListButtton: React.FC<{
  userList: UserList;
  username: string;
  itemLimit?: number;
  itemImageHeight?: any;
  listGridColumns?: number;
  marginBottom?: string;
  tallImages?: boolean;
  theme: UserTheme;
}> = ({
  userList,
  username,
  itemLimit,
  itemImageHeight,
  listGridColumns,
  marginBottom,
  tallImages,
  theme,
}) => {
  const spotifyDisclosure = useDisclosure();
  const deleteDisclosure = useDisclosure();
  const limitedDisclosure = useDisclosure();
  const { data: currentUser } = useCurrentUser();
  const navigate = useNavigate();
  const onDelete = useDeleteListToast(userList, () => {});

  const isHidden = userList.hidden;
  const showSpotify = userList.integrationProviderSlug === "spotify";
  const isLimited = userList.limitedEdition;

  const background = getListButtonBackground(theme, userList, userList.slug);

  return (
    <Box width="100%" pos="relative" mb={marginBottom}>
      <motion.div
        layoutId={`${username}-list-${userList.slug}`}
        // transition={isDragging ? { duration: 0 } : { duration: 0.3 }}
      >
        <PodiumsCard
          align="start"
          p="0.75em"
          bg={background}
          backdropFilter="auto"
          backdropBlur={BACKDROP_BLUR}
          onClick={(e: any) => {
            // e.preventDefault();
            // if (isDragging || isEditing) return;
            navigate(`/${username}/${userList.slug}`);
          }}
          boxShadow={theme.cardShadow}
          borderWidth={theme.cardBorderColor !== "none" ? "1px" : 0}
          borderColor={theme.cardBorderColor}
          cursor="pointer"
          // style={style}
          boxSizing="border-box"
          overflow="hidden"
          _before={theme.name === "Black" ? BLACK_BEFORE : undefined}
          _after={theme.name === "Black" ? BLACK_AFTER : undefined}
        >
          <VStack spacing={2} alignItems="start" width="100%" pos="relative">
            <HStack spacing="6px" width="100%">
              {isHidden && (
                <Icon as={FaEyeSlash} boxSize="18px" color={theme.textColor} />
              )}
              {showSpotify && (
                <Icon
                  as={FaSpotify}
                  boxSize="18px"
                  color={theme.textColor}
                  onClick={(e) => {
                    e.preventDefault();
                    spotifyDisclosure.onOpen();
                  }}
                  cursor="pointer"
                />
              )}
              {isLimited && (
                <Icon
                  as={FaRegGem}
                  boxSize="16px"
                  color={theme.textColor}
                  onClick={(e) => {
                    e.preventDefault();
                    limitedDisclosure.onOpen();
                  }}
                />
              )}
              <Box
                overflow="hidden"
                whiteSpace="nowrap"
                style={{
                  maskImage:
                    "linear-gradient(to right, black 75%, transparent 100%)",
                }}
                w={`calc(100% - ${
                  27 * [isHidden, showSpotify, isLimited].filter(Boolean).length
                }px)`}
              >
                <motion.text layoutId={`${username}-${userList.name}`}>
                  <Heading fontSize="14px" color={theme.textColor}>
                    {userList.name}
                  </Heading>
                </motion.text>
              </Box>
              <Icon
                as={FaChevronRight}
                boxSize="16px"
                color={theme.textColor}
                opacity={0.3}
              />
            </HStack>
            <ListGrid
              listSlug={userList.slug}
              items={userList.items.slice(
                0,
                Math.min(itemLimit ?? 5, userList.items.length)
              )}
              imageHeight={itemImageHeight}
              bgColor={userList.hidden ? "topFive.gray.light" : "white"}
              borderRadius={
                localStorage.getItem("borderRadiusDisabled") !== "true" ||
                borderRadiusDisabledUsers.includes(username)
              }
              columns={listGridColumns}
              imagePadding={IMAGE_PADDING_LISTS.includes(userList.slug) ? 1 : 0}
              enableDetailModal
            />
          </VStack>
        </PodiumsCard>
      </motion.div>
      <SpotifyDialog
        listSlug={userList.slug}
        disclosure={spotifyDisclosure}
        isUpdatingAutomatically={userList.updateAutomatically ?? false}
        onMyProfile={currentUser?.username === username}
      />
      <DeleteListDialog
        deleteDisclosure={deleteDisclosure}
        onDelete={onDelete}
        listName={userList.name}
      />
    </Box>
  );
};

export const getListButtonBackground = (
  theme: UserTheme,
  userList?: UserList,
  str?: string
) => {
  return userList?.hidden
    ? BACKGROUND_HIDDEN
    : theme.name === "Pastel"
    ? stringToPastelColor(str ?? userList?.slug ?? "")
    : theme.name === "Black"
    ? BACKGROUND_DARK_GRADIENT_COLORED
    : theme.cardBg;
};

export default ListButtton;

