import {
  Button,
  ButtonGroup,
  Center,
  Heading,
  HStack,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { FaSpotify } from "react-icons/fa";
import { FaCirclePlus, FaPencil } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import {
  BACKDROP_BLUR,
  BACKGROUND_DARK_GRADIENT_COLORED,
  BLACK_AFTER,
  BLACK_BEFORE,
} from "src/constants";
import { UserTheme } from "src/types/userThemes";
import PodiumsCard from "../PodiumsCard";

const HeyYoureNew: React.FC<{ theme: UserTheme; dismiss: () => void }> = ({
  theme,
  dismiss,
}) => {
  const navigate = useNavigate();
  return (
    <Center w="100%" mt={3} px={3}>
      <PodiumsCard
        align="start"
        px="0.75em"
        bg={
          theme.name === "Black"
            ? BACKGROUND_DARK_GRADIENT_COLORED
            : theme.cardBg
        }
        backdropFilter="auto"
        backdropBlur={BACKDROP_BLUR}
        boxShadow={theme.cardShadow}
        borderWidth={theme.cardBorderColor !== "none" ? "1px" : 0}
        borderColor={theme.cardBorderColor}
        boxSizing="border-box"
        overflow="hidden"
        _before={theme.name === "Black" ? BLACK_BEFORE : undefined}
        _after={theme.name === "Black" ? BLACK_AFTER : undefined}
      >
        <VStack w="100%" spacing={2} alignItems="start">
          <Heading color={theme.textColor} size="md" textAlign="left">
            👋 Hey, you're new!
          </Heading>
          <VStack
            padding={4}
            borderRadius="lg"
            spacing={4}
            alignItems="start"
            color={theme.textColor}
          >
            <HStack spacing={4}>
              <Icon boxSize="20px" as={FaSpotify} />
              <Text>We created lists for you based on your Spotify.</Text>
            </HStack>
            <HStack spacing={4}>
              <Icon boxSize="20px" as={FaPencil} />
              <Text>
                You can edit those lists - add more items, change the order, or
                remove what you don't like.
              </Text>
            </HStack>
            <HStack spacing={4}>
              <Icon boxSize="20px" as={FaCirclePlus} />
              <Text>You can also create other types of lists!</Text>
            </HStack>
          </VStack>
          <ButtonGroup w="100%" justifyContent="end">
            <Button
              variant="outline"
              color={theme.textColor}
              borderColor={theme.textColor}
              onClick={dismiss}
              _hover={{ color: theme.textColor, opacity: 0.7 }}
              mt="5px"
            >
              Dismiss
            </Button>
            <Button onClick={() => navigate("/create/list")} mt="5px">
              Create another list!
            </Button>
          </ButtonGroup>
        </VStack>
      </PodiumsCard>
    </Center>
  );
};
export default HeyYoureNew;
