// Frontend code using TypeScript and React with Chakra UI

import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Spinner,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useFeatureFlagEnabled } from "posthog-js/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SignUpForm from "src/components/Auth/SignUpForm";
import WaitlistForm from "src/components/Auth/WaitlistForm";
import GradientBackground from "src/components/User/UserBackground";
import { CURRENT_USER_QUERY, useCurrentUser } from "src/state/userQueries";
import { errorToast, useLogout } from "src/utils";

const SignUpPage: React.FC = () => {
  const navigate = useNavigate();
  const { data: user, isLoading: isUserLoading } = useCurrentUser();
  const [isLoading, setLoading] = useState(false);
  const waitlistEnabled = useFeatureFlagEnabled("waitlist");
  const queryClient = useQueryClient();

  const toast = useToast();
  const handleLogout = useLogout();
  const isUsernameNotSet = user?.username.startsWith("!not-set-") ?? false;

  useEffect(() => {
    if (
      user &&
      (isUsernameNotSet ||
        (user.email && !user.loginMethod && !user.emailVerified))
    ) {
      return;
    } else if (!!user) {
      navigate("/me");
      return;
    }
    setLoading(true);

    // Check for accessToken and refreshToken in URL query params
    const params = new URLSearchParams(window.location.search);
    const accessToken = params.get("accessToken");
    const refreshToken = params.get("refreshToken");

    const error = params.get("error");
    if (accessToken && refreshToken) {
      // If the tokens exist, save them in localStorage
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      queryClient.invalidateQueries({ queryKey: CURRENT_USER_QUERY });
    } else if (error) {
      // If there is an error, display an error toast
      errorToast(error, toast);
    }
    setLoading(false);
  }, [isUsernameNotSet, navigate, queryClient, toast, user]);

  const Inner = () => {
    if (isLoading || isUserLoading) {
      return (
        <Center minHeight="85vh">
          <VStack spacing={4}>
            <Spinner size="xl" />
            <Heading>Loading...</Heading>
          </VStack>
        </Center>
      );
    } else if (user?.waitlist) {
      // waitlist
      return (
        <Center minH="85vh" p="2em">
          <VStack>
            <Alert status="success" borderRadius="md">
              <AlertIcon />
              You're on the waitlist! We'll reach out when Podiums is ready.
            </Alert>
            <HStack mt="1em">
              <Text>Made a mistake?</Text>
              <Button onClick={handleLogout}>Logout</Button>
            </HStack>
          </VStack>
        </Center>
      );
    } else if (waitlistEnabled) {
      return <WaitlistForm />;
    } else {
      return (
        <SignUpForm
          usernameNotSet={isUsernameNotSet}
          firstName={user?.firstName}
          lastName={user?.lastName}
          googleProfilePicture={user?.profilePicture}
        />
      );
    }
  };
  return (
    <GradientBackground>
      <Box width="100%" mx="auto" px="1em" color="white">
        <Inner />
      </Box>
    </GradientBackground>
  );
};

export default SignUpPage;
