import { Box, HStack } from "@chakra-ui/react";
import React from "react";
import { UserList } from "src/types/list";

import { TALL_IMAGE_LISTS } from "src/constants";
import { UserTheme } from "src/types/userThemes";
import ListButtton from "./ListButton";

const TwoColumnListOfLists: React.FC<{
  leftCol: UserList[];
  rightCol: UserList[];
  username: string;
  theme: UserTheme;
}> = ({ leftCol, rightCol, username, theme }) => {
  return (
    <Box width="100%" maxW="450px">
      <HStack spacing={3} alignItems="start">
        <Column items={leftCol} username={username} theme={theme} />
        <Column items={rightCol} username={username} theme={theme} />
      </HStack>
    </Box>
  );
};

const Column: React.FC<{
  items: UserList[];
  username: string;
  theme: UserTheme;
}> = ({ items, username, theme }) => {
  return (
    <Box w="calc(50% - 0.25em)">
      {items.map((item) => {
        const tallImages = TALL_IMAGE_LISTS.includes(item.slug);
        return (
          <ListButtton
            key={item._id}
            userList={item}
            username={username}
            marginBottom="10px"
            itemLimit={4}
            listGridColumns={2}
            itemImageHeight={
              tallImages
                ? { base: "6.25em", sm: "8.5em" }
                : { base: "4.5em", sm: "6em" }
            }
            theme={theme}
          />
        );
      })}
    </Box>
  );
};

export default TwoColumnListOfLists;
