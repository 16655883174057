import { Center, Heading, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { Header } from "src/components/Header/Header";
import EditList from "src/components/Lists/Edit/EditList";
import PodiumsCard from "src/components/PodiumsCard";
import { ShimmerListOptions } from "src/components/Shimmer";
import GradientBackground from "src/components/User/UserBackground";
import { useCurrentUser } from "src/state/userQueries";
import { UserList } from "src/types/list";

export const CreateListFromScratch: React.FC<{
  slug: string;
  name: string;
  onBack: () => void;
}> = ({ slug, name, onBack }) => {
  const [isEditing, setIsEditing] = useState(false);
  const { data } = useCurrentUser();
  const list: UserList = {
    name: name,
    slug: slug,
    items: [],
    updatedAt: "",
  };
  if (!data) {
    return (
      <GradientBackground>
        <Header />
        <Center minH="80vh" width="100%">
          <PodiumsCard p="2em" m="1em">
            <VStack width="100%" spacing={3}>
              <Heading textAlign="center" fontSize="30px">
                Create a list!
              </Heading>
              <ShimmerListOptions />
            </VStack>
          </PodiumsCard>
        </Center>
      </GradientBackground>
    );
  }
  return (
    <GradientBackground>
      <Header />
      <Center justifyContent="center" width="100%">
        <EditList
          user={data}
          list={list}
          setIsEditing={(e) => {
            setIsEditing(e);
            if (!isEditing) {
              onBack();
            }
          }}
          fromScratch
        />
      </Center>
    </GradientBackground>
  );
};
export default CreateListFromScratch;
