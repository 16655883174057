import React from "react";

export const MyFollowers: React.FC = () => {
  return <></>;
  // const query = useMyFollowers();
  // return <ListOfUsers query={query} />;
};

export const MyFollowing: React.FC = () => {
  return <></>;
  // const query = useMyFollowing();
  // return <ListOfUsers query={query} />;
};
