import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Circle,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { FaCheck, FaCircleCheck } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import {
  BACKGROUND_DARK_GRADIENT_COLORED,
  BACKGROUND_FADED,
  BLACK_AFTER,
  BLACK_BEFORE,
} from "src/constants";
import { useUpdateMe } from "src/state/userQueries";
import { SanitizedOtherUser } from "src/types/user";
import { ALL_THEMES, getTheme, UserTheme } from "src/types/userThemes";
import { stringToPastelColor } from "src/utils";
import { ActionButton } from "../Buttons";
import PodiumsCard from "../PodiumsCard";
import { ProfilePicture } from "./UserProfileHeader";

interface Props {
  user: SanitizedOtherUser;
  disclosure: { isOpen: boolean; onOpen: () => void; onClose: () => void };
  setLocalOneColumn: (oneCol: boolean) => void;
  setLocalTheme: (t: string) => void;
}

const EditProfileModal: React.FC<Props> = ({
  user,
  disclosure: { isOpen, onClose },
  setLocalOneColumn,
  setLocalTheme,
}) => {
  const [theme, setTheme] = useState<string>(user.backgroundType ?? "");
  const [isOneColumn, setIsOneColumn] = useState<boolean>(
    user.listLayout.length === 1
  );
  const [username, setUsername] = useState(user.username);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);

  const themeHasChanged = theme !== user.backgroundType;
  const layoutHasChanged = isOneColumn !== (user.listLayout.length === 1);

  const updateUser = useUpdateMe();
  const toast = useToast();
  const navigate = useNavigate();

  const handleColumnChange = useCallback(
    (singleCol: boolean) => {
      setIsOneColumn(singleCol);
      setLocalOneColumn(singleCol);
    },
    [setLocalOneColumn]
  );

  const handleThemeChange = useCallback(
    (theme: string) => {
      setTheme(theme);
      setLocalTheme(theme);
    },
    [setLocalTheme]
  );

  useEffect(() => {
    setUsername(user.username);
    setFirstName(user.firstName ?? "");
    setLastName(user.lastName ?? "");
    handleThemeChange(user.backgroundType ?? "");
    handleColumnChange(user.listLayout.length === 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isOpen]);

  const handleUsernameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setUsername(e.target.value);
    },
    []
  );

  const handleFirstNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFirstName(e.target.value);
    },
    []
  );

  const handleLastNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setLastName(e.target.value);
    },
    []
  );

  const handleSave = async () => {
    if (
      themeHasChanged ||
      layoutHasChanged ||
      username !== user.username ||
      firstName !== user.firstName ||
      lastName !== user.lastName
    ) {
      const userPromise = updateUser.mutateAsync({
        backgroundType: themeHasChanged ? theme : undefined,
        isOneColumn: layoutHasChanged ? isOneColumn : undefined,
        firstName: firstName !== user.firstName ? firstName : undefined,
        lastName: lastName !== user.lastName ? lastName : undefined,
        username: username !== user.username ? username : undefined,
      });
      toast.promise(userPromise, {
        success: (newU) => {
          onClose();
          navigate(`/${newU.username}`);
          return { title: "Saved!" };
        },
        error: (error: any) => {
          setUsername(user.username);
          setFirstName(user.firstName ?? "");
          setLastName(user.lastName ?? "");
          setLocalTheme(user.backgroundType ?? "");
          setIsOneColumn(user.listLayout.length === 1);
          if (error.response?.status === 400) {
            return { title: "Error", description: error.response.data.message };
          } else {
            // If the request was not successful, handle the error
            if (process.env.REACT_APP_DEBUG) {
              console.error("Error during update profile:", error);
            }
            return { title: "Error saving" };
          }
        },
        loading: { title: "Loading..." },
      });
    } else {
      onClose();
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        motionPreset="slideInBottom"
        isCentered={false}
        size="xl"
      >
        <ModalOverlay bg="blackAlpha.200" backdropFilter="blur(1px)" />
        <ModalContent
          position="fixed"
          borderRadius="xl"
          borderBottomRadius={0}
          bottom="0"
          margin="0"
          color="black"
        >
          <ModalHeader p="12px 12px 0px 12px">
            <HStack justify="space-between">
              <Heading
                as={"h2"}
                fontSize="22px"
                fontWeight={500}
                color="topFive.black"
              >
                Edit Profile
              </Heading>
              <HStack spacing={4}>
                <ModalCloseButton
                  size="lg"
                  pos="relative"
                  top={0}
                  boxSize="1em"
                  right={0}
                  padding={0}
                  _hover={{ bg: "none" }}
                />
                <ActionButton
                  icon={FaCheck}
                  onClick={handleSave}
                  padding={2}
                  size="1.5em"
                  color="black"
                  border={"2px solid black"}
                />
              </HStack>
            </HStack>
          </ModalHeader>
          <ModalBody px="12px" pb="1.25em">
            <VStack alignItems="start" width="100%">
              <VStack spacing={2} width="100%">
                <HStack w="100%" spacing={3}>
                  <Box minW="fit-content">
                    <ProfilePicture
                      allowEdit
                      src={user.profilePicture}
                      size={5}
                    />
                  </Box>
                  <FormControl>
                    <FormLabel>Username</FormLabel>
                    <InputGroup variant="outline">
                      <InputLeftAddon
                        bg="#ffffff"
                        borderRight="none"
                        pr={1}
                        color="gray.400"
                      >
                        podiu.ms/
                      </InputLeftAddon>
                      <Input
                        type="text"
                        value={username}
                        onChange={handleUsernameChange}
                        placeholder="Username"
                        required
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="none"
                        spellCheck="false"
                        borderLeft="none"
                        pl={0.5}
                      />
                    </InputGroup>
                  </FormControl>
                </HStack>
                <FormControl>
                  <FormLabel>Name</FormLabel>
                  <Input
                    type="text"
                    value={firstName}
                    onChange={handleFirstNameChange}
                    placeholder="First name"
                    required
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="none"
                    spellCheck="false"
                    width="50%"
                    variant="outline"
                    borderRightRadius={0}
                  />
                  <Input
                    type="text"
                    value={lastName}
                    onChange={handleLastNameChange}
                    placeholder="Last name"
                    required
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="none"
                    spellCheck="false"
                    width="50%"
                    borderLeftWidth="0.5px"
                    variant="outline"
                    borderLeftColor="gray.300"
                    borderLeftRadius={0}
                  />
                </FormControl>
              </VStack>
              <Heading as={"h2"} fontSize="22px" fontWeight={500}>
                Theme
              </Heading>
              <ProfileLayoutToggle
                isOneColumn={isOneColumn}
                handleColumnChange={handleColumnChange}
              />
              <ThemeSelection
                handleThemeChange={handleThemeChange}
                isOneColumn={isOneColumn}
                curTheme={theme}
              />
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const ThemeButton: React.FC<{
  isOneColumn: boolean;
  themeSlug: string;
  theme: UserTheme;
  handleThemeChange: (t: string) => void;
  selected: boolean;
  textColor?: string;
}> = ({
  isOneColumn,
  themeSlug,
  theme,
  handleThemeChange,
  selected,
  textColor,
}) => {
  const MiniListButton: React.FC<{
    children: ReactNode;
    cardIndex: number;
    [key: string]: any;
  }> = ({ children, cardIndex, ...rest }) => {
    return (
      <PodiumsCard
        borderRadius="6px"
        py="5px"
        px="5px"
        display={"flex"}
        flexDirection="row"
        gap={1}
        background={
          themeSlug === "pastel"
            ? stringToPastelColor((8 * cardIndex).toString())
            : themeSlug === "black"
            ? BACKGROUND_DARK_GRADIENT_COLORED
            : theme.cardBg
        }
        _before={
          themeSlug === "black"
            ? { ...BLACK_BEFORE, borderRadius: "6px" }
            : undefined
        }
        _after={
          themeSlug === "black"
            ? { ...BLACK_AFTER, borderRadius: "6px" }
            : undefined
        }
        borderWidth={theme.cardBorderColor !== "none" ? "1px" : 0}
        borderColor={theme.cardBorderColor}
        boxShadow={theme.cardShadow}
        boxSizing="border-box"
        {...rest}
      >
        {children}
      </PodiumsCard>
    );
  };

  return (
    <Box
      key="rows"
      textAlign="center"
      cursor="pointer"
      transition="all 0.3s"
      onClick={() => handleThemeChange(themeSlug)}
    >
      <Box
        pos="relative"
        w="100%"
        bg="white"
        borderRadius="xl"
        overflow="hidden"
        borderWidth="1px"
        borderColor="gray.400"
      >
        <VStack
          w="110px"
          spacing={1}
          h="170px"
          className={`bg-${themeSlug}`}
          px="8px"
          py="10px"
          opacity={selected ? 0.5 : 1}
        >
          <MiniListButton
            cardIndex={20}
            background={
              themeSlug === "black"
                ? BACKGROUND_DARK_GRADIENT_COLORED
                : themeSlug === "midnight"
                ? "topFive.black"
                : theme.cardBg === BACKGROUND_FADED
                ? BACKGROUND_FADED
                : "white"
            }
            w="100%"
          >
            <Circle size="20px" bg="gray.400"></Circle>
            <VStack alignItems="start" spacing={1}>
              <Box
                width="42px"
                height="6px"
                bg="gray.400"
                borderRadius="5px"
              ></Box>
              <Box
                width="26px"
                height="6px"
                bg="gray.400"
                borderRadius="5px"
              ></Box>
            </VStack>
          </MiniListButton>
          {isOneColumn ? (
            [...Array(6)].map((_, cardIndex) => (
              <MiniListButton cardIndex={cardIndex}>
                {[...Array(5)].map((_, index) => (
                  <Box
                    key={index}
                    borderRadius="sm"
                    width={!!theme.cardBorderColor ? "13px" : "15px"}
                    height={cardIndex % 2 === 1 ? "22px" : "15px"}
                    bg="gray.400"
                  />
                ))}
              </MiniListButton>
            ))
          ) : (
            <HStack spacing={1} w="100%" alignItems="start">
              <VStack gap={1} w="100%">
                {[...Array(3)].map((_, cardIndex) => (
                  <MiniListButton
                    cardIndex={cardIndex}
                    display="grid"
                    gridTemplateColumns="1fr 1fr"
                    gap={1}
                    pl="7px"
                  >
                    {[...Array(4)].map((_, index) => (
                      <Box
                        key={index}
                        borderRadius="sm"
                        width={!!theme.cardBorderColor ? "13px" : "15px"}
                        height={cardIndex % 2 === 1 ? "20px" : "15px"}
                        bg="gray.400"
                      />
                    ))}
                  </MiniListButton>
                ))}
              </VStack>
              <VStack gap={1} w="100%">
                {[...Array(3)].map((_, cardIndex) => (
                  <MiniListButton
                    cardIndex={cardIndex + 3}
                    display="grid"
                    gridTemplateColumns="1fr 1fr"
                    gap={1}
                    justifyContent="center"
                    alignItems="center"
                    pl="7px"
                  >
                    {[...Array(4)].map((_, index) => (
                      <Box
                        key={index}
                        borderRadius="sm"
                        width={!!theme.cardBorderColor ? "13px" : "15px"}
                        height={cardIndex % 2 === 0 ? "20px" : "15px"}
                        bg="gray.400"
                      />
                    ))}
                  </MiniListButton>
                ))}
              </VStack>
            </HStack>
          )}
        </VStack>
        {selected && (
          <Box
            pos="absolute"
            left={0}
            top={0}
            w="100%"
            h="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Icon
              as={FaCheckCircle}
              color="topFive.black"
              boxSize="2em"
              flex={1}
            />
          </Box>
        )}
      </Box>
      <Center height="45px">
        <Text
          fontWeight={500}
          display="inline-block"
          whiteSpace="normal"
          w="100px"
          lineHeight={1.3}
          mt="5px"
          color={textColor ?? "topFive.black"}
        >
          {theme.name}
        </Text>
      </Center>
    </Box>
  );
};

const SimpleThemeButton: React.FC<{
  themeSlug: string;
  selected: boolean;
  handleThemeChange: (t: string) => void;
}> = ({ themeSlug, selected, handleThemeChange }) => {
  const theme = getTheme(themeSlug);
  const Inner = () => {
    if (themeSlug === "black") {
      return (
        <Button variant="dark" minW="100px" opacity={selected ? 0.5 : 1}>
          Black
        </Button>
      );
    }
    return (
      <Box
        py="6px"
        px="24px"
        pos="relative"
        className={`bg-${themeSlug}`}
        borderRadius="full"
        boxShadow={theme.cardShadow}
        height="100%"
        opacity={selected ? 0.5 : 1}
        background={themeSlug === "midnight" ? "topFive.black" : undefined}
      >
        <Text
          color={
            themeSlug === "pastel"
              ? "rgb(156, 205, 255)"
              : theme.textOnBackgroundColor
          }
          fontWeight={600}
        >
          {theme.name}
        </Text>
      </Box>
    );
  };
  return (
    <Box
      borderRadius="full"
      minW="fit-content"
      overflow="hidden"
      pos="relative"
      onClick={() => handleThemeChange(themeSlug)}
      cursor="pointer"
    >
      <Inner />
      {selected && (
        <Box
          pos="absolute"
          left={0}
          top={0}
          w="100%"
          h="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex={2}
        >
          <Icon as={FaCheckCircle} color="white" boxSize="1.5em" flex={1} />
        </Box>
      )}
    </Box>
  );
};

export const ProfileLayoutToggle: React.FC<{
  isOneColumn: boolean;
  handleColumnChange: (singleCol: boolean) => void;
}> = ({ isOneColumn, handleColumnChange }) => {
  return (
    <ButtonGroup
      w="100%"
      display="flex"
      borderWidth="2px"
      borderColor="gray.200"
      p="3px"
      borderRadius="lg"
    >
      <Button
        flex={1}
        bg={isOneColumn ? "gray.200" : "white"}
        height="36px"
        onClick={() => handleColumnChange(true)}
        transition="0.3s all"
        leftIcon={isOneColumn ? <FaCircleCheck /> : undefined}
        _focusVisible={{ bg: isOneColumn ? "gray.200" : "white" }}
      >
        Rows
      </Button>
      <Button
        flex={1}
        bg={!isOneColumn ? "gray.200" : "white"}
        height="36px"
        onClick={() => handleColumnChange(false)}
        transition="0.3s all"
        leftIcon={isOneColumn ? undefined : <FaCircleCheck />}
        _focusVisible={{ bg: isOneColumn ? "gray.200" : "white" }}
      >
        Grid
      </Button>
    </ButtonGroup>
  );
};

export const ThemeSelection: React.FC<{
  handleThemeChange: (theme: string) => void;
  isOneColumn: boolean;
  curTheme: string;
  textColor?: string;
  simple?: boolean;
}> = ({ handleThemeChange, isOneColumn, curTheme, textColor, simple }) => {
  const themeSlugs = ALL_THEMES.filter((t) => !t.includes("image"));
  return (
    <Box
      mx={-3}
      px={3}
      pos="relative"
      overflowX="scroll"
      overflowY="hidden"
      whiteSpace="nowrap"
      sx={{
        "&::-webkit-scrollbar": {
          display: "none",
        },
        msOverflowStyle: "none", // for Internet Explorer and Edge
        scrollbarWidth: "none", // for Firefox
      }}
      width="calc(100% + 1.5em)"
    >
      <Flex gap="0.5em">
        {simple
          ? themeSlugs.map((t) => (
              <SimpleThemeButton
                key={t}
                themeSlug={t}
                selected={curTheme === t}
                handleThemeChange={handleThemeChange}
              />
            ))
          : themeSlugs.map((t) => (
              <ThemeButton
                key={t}
                themeSlug={t}
                theme={getTheme(t)}
                handleThemeChange={handleThemeChange}
                isOneColumn={isOneColumn}
                selected={t === curTheme}
                textColor={textColor}
              />
            ))}
        <Box minW={2}></Box>
      </Flex>
    </Box>
  );
};

export default EditProfileModal;
