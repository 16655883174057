// returns the width of the image for the list in pixels
export const getListImageWidth = (listSlug: string): number => {
  switch (listSlug) {
    case "movies":
    case "shows":
    case "books":
      return 55;
    case "drinks":
      return 40;
    case "snacks":
      return 60;
    default:
      return 80;
  }
};
