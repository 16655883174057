import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Tag,
  Text,
  UseDisclosureReturn,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { FaSpotify } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useGetMovieDetails, useGetShowDetails } from "src/state/listQueries";
import { ListItem, StreamingProvider } from "src/types/list";

const ItemModal: React.FC<{
  listSlug: string;
  item: ListItem;
  disclosure: UseDisclosureReturn;
  tall?: boolean;
}> = ({ listSlug, item, disclosure, tall }) => {
  const movieDetailsEnabled =
    !!item.externalID && listSlug === "movies" && disclosure.isOpen;

  const showDetailsEnabled =
    !!item.externalID && listSlug === "shows" && disclosure.isOpen;

  const { data: movieData, isLoading: movieIsLoading } = useGetMovieDetails(
    item.externalID ?? "",
    movieDetailsEnabled
  );

  const { data: showData, isLoading: showIsLoading } = useGetShowDetails(
    item.externalID ?? "",
    showDetailsEnabled
  );

  return (
    <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose} size="xl">
      <ModalOverlay onClick={disclosure.onClose} backdropFilter="blur(8px)" />
      <ModalContent bg="transparent" boxShadow="none" borderRadius="none">
        <ModalBody>
          <ModalCloseButton
            color="#ccc"
            size="md"
            borderColor="#ccc"
            borderWidth="1px"
            borderRadius="full"
            padding="10px"
          />
          <Center h="100%">
            <VStack>
              <Image
                src={item.imageURI}
                alt={item.title}
                h="12em"
                maxW={"12em"}
                objectFit="cover"
                boxShadow="md"
                background="white"
                borderRadius={
                  localStorage.getItem("borderRadiusDisabled") === "true"
                    ? undefined
                    : "4px"
                }
                mb="0.5em"
              />
              <Heading color="white" fontSize="1.4em" textAlign="center">
                {item.title}
              </Heading>
              {item.subtitle && (
                <Heading
                  color="topFive.gray.light"
                  fontSize="1em"
                  textAlign="center"
                >
                  {item.subtitle}
                </Heading>
              )}
              {item.link && (
                <Button
                  as={Link}
                  to={item.link}
                  leftIcon={<Icon as={FaSpotify} />}
                  mt="5px"
                >
                  Open in Spotify
                </Button>
              )}
              {movieDetailsEnabled && (
                <VStack spacing={4} align="start" color="white" mt="1em">
                  {movieIsLoading && (
                    <Center>
                      <Spinner />
                    </Center>
                  )}
                  {movieData && (
                    <VStack align="start" spacing="16px">
                      <Heading fontSize="1.2em">Overview</Heading>
                      <HStack align="start">
                        {movieData.genres.map((genre) => (
                          <Tag key={genre.name} px="16px" borderRadius="full">
                            {genre.name}
                          </Tag>
                        ))}
                      </HStack>
                      <Text fontSize="1em">{movieData.overview}</Text>
                      <Providers providers={movieData.providers} />
                    </VStack>
                  )}
                </VStack>
              )}
              {showDetailsEnabled && (
                <VStack spacing={4} align="start" color="white" mt="1em">
                  {showIsLoading && (
                    <Center>
                      <Spinner />
                    </Center>
                  )}
                  {showData && (
                    <VStack align="start" spacing="16px">
                      <Heading fontSize="1.2em">Overview</Heading>
                      <HStack align="start">
                        {showData.genres.map((genre, index) => (
                          <Tag key={genre.name} px="16px" borderRadius="full">
                            {genre.name}
                          </Tag>
                        ))}
                      </HStack>
                      <Text fontSize="1em" fontWeight={600}>
                        {showData.number_of_seasons} seasons
                      </Text>
                      <Text fontSize="1em" mt="-2px">
                        {showData.overview}
                      </Text>
                      <Providers providers={showData.providers} />
                    </VStack>
                  )}
                </VStack>
              )}
            </VStack>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const Providers: React.FC<{
  providers: StreamingProvider[];
}> = ({ providers }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!providers.length) {
    return null;
  }

  return (
    <>
      <Heading fontSize="1.2em" mt="8px">
        Where to Watch
      </Heading>
      <Box
        maxW="100vw"
        px="2em"
        mx="-2em"
        pos="relative"
        overflowX="scroll"
        whiteSpace="nowrap"
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none", // for Internet Explorer and Edge
          scrollbarWidth: "none", // for Firefox
        }}
      >
        <Flex
          align="start"
          ml={isExpanded ? "-8px" : "10px"}
          transition={"all 0.3s"}
        >
          {providers
            .slice(
              0,

              4 + (isExpanded ? providers.length - 4 : 0)
            )
            .map((provider) => (
              <VStack
                spacing={0}
                ml={isExpanded ? "8px" : "-10px"}
                minW="fit-content"
              >
                <Image
                  src={provider.logo_path}
                  alt={provider.provider_name}
                  w="50px"
                  h="50px"
                  fit="cover"
                  mb="5px"
                  borderRadius="full"
                  border="3px solid #fff"
                  boxSizing="border-box"
                  transition={"all 0.3s"}
                />
                {/* <Collapse in={isExpanded}>
                  {provider.watch_options.map((option) => (
                    <Text key={option} fontSize="12px" color="#fff">
                      {option}
                    </Text>
                  ))}
                </Collapse> */}
              </VStack>
            ))}
          {providers.length > 4 && (
            <Button
              size="md"
              variant="link"
              color="#fff"
              onClick={() => setIsExpanded(!isExpanded)}
              py="12px"
              px="10px"
              minW="fit-content"
            >
              {isExpanded ? (
                <>
                  <Icon as={FaChevronLeft} fontSize="18px" mr="4px" /> Collapse
                </>
              ) : (
                `+${providers.length - 4} more`
              )}
            </Button>
          )}
        </Flex>
      </Box>
    </>
  );
};

export default ItemModal;
