import { Center, Heading, UseDisclosureReturn, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import "src/backgrounds.css";
import { PodiumsThemedCard } from "src/components/PodiumsCard";
import { UserList } from "src/types/list";
import { SanitizedOtherUser } from "src/types/user";
import { getTheme } from "src/types/userThemes";
import OneColumnListOfLists from "./OneColumnListOfLists";
import TwoColumnListOfLists from "./TwoColumnListOfLists";

const ListOfLists: React.FC<{
  user: SanitizedOtherUser;
  onMyProfile: boolean;
  localIsSingleColumn?: boolean;
  editListsDisclosure?: UseDisclosureReturn;
}> = ({ user, onMyProfile, localIsSingleColumn, editListsDisclosure }) => {
  const [lists, setLists] = useState<UserList[]>(user.lists);
  const [leftColLists, setLeftColLists] = useState<UserList[]>(
    user.leftColLists ?? []
  );
  const [rightColLists, setRightColLists] = useState<UserList[]>(
    user.rightColLists ?? []
  );

  useEffect(() => {
    setLists(user.lists);
    setLeftColLists(user.leftColLists ?? []);
    setRightColLists(user.rightColLists ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, editListsDisclosure?.isOpen]);

  const theme = getTheme(user.backgroundType);

  return (
    <Center width="100%" position="relative" pt={3} pb="2em">
      <VStack position="relative" spacing={4} width="100%">
        {lists.length > 0 ? (
          <Center width="100%" px={3}>
            {localIsSingleColumn ?? user.listLayout.length === 1 ? (
              <OneColumnListOfLists
                username={user?.username ?? ""}
                lists={lists}
                setLists={setLists}
                theme={theme}
              />
            ) : (
              <TwoColumnListOfLists
                username={user?.username ?? ""}
                leftCol={leftColLists}
                rightCol={rightColLists}
                theme={theme}
              />
            )}
          </Center>
        ) : (
          <Center width="100%" px={3}>
            <PodiumsThemedCard theme={theme}>
              <Heading size="4xl">😥</Heading>
              <Heading color={theme.textColor}>
                {onMyProfile ? "You have" : `${user?.username} has`} no lists!
              </Heading>
            </PodiumsThemedCard>
          </Center>
        )}
      </VStack>
    </Center>
  );
};

export default ListOfLists;
