import {
  Box,
  Button,
  ButtonGroup,
  Card,
  HStack,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FaHome, FaSignInAlt } from "react-icons/fa";
import { FaBars, FaBolt, FaMagnifyingGlass, FaUser } from "react-icons/fa6";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import {
  BACKDROP_BLUR,
  BACKGROUND_DARK_GRADIENT_COLORED,
  BLACK_AFTER,
  BLACK_BEFORE,
} from "src/constants";
import { useCurrentUser } from "src/state/userQueries";
import { getTheme, UserTheme } from "src/types/userThemes";
import { ActionButton } from "../Buttons";
import { HomePageHeader } from "./HomePageHeader";
import { ProfileButton } from "./ProfileButton";
import { ShareButton } from "./ShareButton";

const PRIVATE_ROUTES = [
  "/me",
  "/me/activity",
  "/create/list",
  "/create/pin",
  "/onboarding",
];

export const Logo: React.FC<{
  height?: string | { base: string; md: string };
  width?: string;
  linkDisabled?: boolean;
  color?: string;
}> = ({ height, width, linkDisabled, color }) => {
  const InnerLogo = () => {
    return (
      <Image
        src={`/podiums-logo${
          !!color ? (color.includes("-faded") ? "-white" : `-${color}`) : ""
        }.png`}
        opacity={!!color && color.includes("-faded") ? 0.85 : 1}
        h={height}
        w={width}
      />
    );
  };
  return linkDisabled ? (
    <InnerLogo />
  ) : (
    <Link as={RouterLink} to="/">
      <InnerLogo />
    </Link>
  );
};

export const AppIconLogo: React.FC<{
  height?: string;
  width?: string;
  linkDisabled?: boolean;
  color?: string;
}> = ({ height, width, linkDisabled, color }) => {
  return true ? (
    <Logo height="100" color="original" />
  ) : (
    <Card
      boxSize="100px"
      justifyContent="center"
      alignItems="center"
      display="flex"
      borderRadius="15px"
      background={BACKGROUND_DARK_GRADIENT_COLORED}
      backgroundClip="border-box"
      _before={BLACK_BEFORE}
      _after={BLACK_AFTER}
    >
      <Logo height="55px" color={color} />
    </Card>
  );
};

export const ActivityButton = () => {
  return (
    <ActionButton icon={FaBolt} bg="white" as={RouterLink} to="/activity" />
  );
};

export const ExploreButton = () => {
  return (
    <ActionButton
      icon={FaMagnifyingGlass}
      bg="white"
      as={RouterLink}
      to="/explore"
    />
  );
};

export const Header: React.FC<{
  hideSearch?: boolean;
  theme?: UserTheme;
  noLogo?: boolean;
}> = ({ hideSearch, noLogo, theme }) => {
  const navigate = useNavigate();
  const { data: user, isLoading } = useCurrentUser();
  const location = useLocation();

  const curTheme =
    theme ??
    (!!user?.backgroundType ? getTheme(user?.backgroundType) : undefined);

  useEffect(() => {
    if (isLoading) return;
    const accessingPrivateRoutesOrProfile = [
      ...PRIVATE_ROUTES,
      `/${user?.username}`,
      `/${user?.username}/`,
    ].includes(location.pathname);

    if (
      // logged out and trying to access private routes
      (!user && PRIVATE_ROUTES.includes(location.pathname)) ||
      // logged in but user has not set their username and trying to access private routes or their profile
      (!!user &&
        user.username.startsWith("!not-set-") &&
        accessingPrivateRoutesOrProfile)
    ) {
      navigate("/signup");
    } else if (!user?.hasOnboarded && accessingPrivateRoutesOrProfile) {
      navigate("/onboarding");
    }
    // else if (user.email && !user.loginMethod && !user.emailVerified) {
    // Go to verify email page
    // if (location.pathname !== "/verify-email" && location.pathname !== "/") {
    //   console.log("navigating to /verify-email");
    //   navigate("/verify-email");
    // }
    // }
  }, [user, isLoading, location.pathname, navigate]);

  if (
    isLoading ||
    location.pathname === "/" ||
    user?.username.startsWith("!not-set-")
    //  || (user.email && !user.loginMethod && !user.emailVerified)
  ) {
    return (
      <HomePageHeader
        logoColor={curTheme?.logoColor}
        navButtonBg={curTheme?.navButtonBg}
        noLogo={noLogo}
      />
    );
  } else if (!user) {
    return (
      <LoggedOutHeader
        isHome={location.pathname === "/"}
        logoColor={curTheme?.logoColor}
        navButtonBg={curTheme?.navButtonBg}
        noLogo={noLogo}
      />
    );
  } else {
    return (
      <HStack px={3} py="0.75em" justify="space-between" alignItems="center">
        <HStack>
          {!noLogo && (
            <Logo height="40px" color={curTheme?.logoColor ?? "white"} />
          )}
        </HStack>
        <HStack
          alignItems="center"
          bg={curTheme?.navButtonBg}
          backdropFilter="auto"
          backdropBlur={BACKDROP_BLUR}
          borderRadius="full"
          p="4px"
          spacing="6px"
          boxShadow={curTheme?.cardShadow}
          borderWidth={curTheme?.cardBorderColor !== "none" ? "1px" : 0}
          borderColor={curTheme?.cardBorderColor}
        >
          <ShareButton username={user.username} />
          <ProfileButton user={user} />
        </HStack>
      </HStack>
    );
  }
};

const LoggedOutHeader: React.FC<{
  isHome?: boolean;
  logoColor?: string;
  navButtonBg?: string;
  noLogo?: boolean;
}> = ({ isHome, logoColor, navButtonBg, noLogo }) => {
  return (
    <HStack
      px={3}
      py="0.75em"
      justify="space-between"
      alignItems="center"
      width="100%"
      className={isHome ? "bg-party-gradient" : undefined}
    >
      {noLogo ? <Box></Box> : <Logo height="40px" color={logoColor} />}

      <Box>
        <ButtonGroup display={{ base: "none", md: "block" }}>
          <Button
            variant="light"
            bg={navButtonBg ?? "white"}
            as={RouterLink}
            to="/login"
            leftIcon={<FaSignInAlt />}
            borderRadius="full"
          >
            Login
          </Button>
          <Button
            variant="light"
            bg={navButtonBg ?? "white"}
            as={RouterLink}
            to="/signup"
            leftIcon={<FaUser />}
            borderRadius="full"
          >
            Sign up
          </Button>
        </ButtonGroup>
        <Box display={{ base: "block", md: "none" }}>
          <Menu>
            <MenuButton
              aria-label="Options"
              bg="none"
              p={0}
              color={!!logoColor ? logoColor : "white"}
              fontSize="2em"
              as={IconButton}
              icon={<FaBars />}
              _hover={{ bg: "none", color: "topFive.gray.light" }}
              _active={{ bg: "none", color: "topFive.gray.light" }}
            ></MenuButton>
            <MenuList boxShadow="0px 8px 20px 0px rgba(0,0,0,0.2)" zIndex={100}>
              {!isHome && (
                <MenuItem icon={<FaHome />} as={RouterLink} to="/">
                  What's Podiums?
                </MenuItem>
              )}
              <MenuItem icon={<FaSignInAlt />} as={RouterLink} to="/login">
                Login
              </MenuItem>
              <MenuItem icon={<FaUser />} as={RouterLink} to="/signup">
                Sign up
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Box>
    </HStack>
  );
};
