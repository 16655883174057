import {
  Box,
  Card,
  Center,
  Collapse,
  HStack,
  Heading,
  Icon,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import {
  FaCheck,
  FaChevronLeft,
  FaEye,
  FaEyeSlash,
  FaRegCircleXmark,
  FaSpotify,
  FaTrashCan,
} from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { ProfilePicture } from "src/components/User/UserProfileHeader";
import { BACKDROP_BLUR, BACKGROUND_HIDDEN } from "src/constants";
import {
  useDeleteList,
  useRefreshList,
  useUpdateList,
} from "src/state/userQueries";
import { Pin } from "src/types/pin";
import { SanitizedOtherUser } from "src/types/user";
import { getTheme } from "src/types/userThemes";
import { listItemsChanged, stringToPastelColor } from "src/utils";
import { ListItem as ListItemType, UserList } from "../../../types/list";
import { ActionButton } from "../../Buttons";
import ListDialogs from "../Utils/ListDialogs";
import { UpdateSpotifyButton } from "../Utils/ListIcon";
import { SearchField } from "../Utils/Search";
import { SortableList } from "../Utils/SortableList";
import ListItem from "../View/ListItem";
import { getListImageWidth } from "../utils";

interface ListProps {
  user: SanitizedOtherUser;
  list: UserList;
  setIsEditing: (isEditing: boolean) => void;
  fromScratch?: boolean;
}

const EditList: React.FC<ListProps> = ({
  user,
  list,
  setIsEditing,
  fromScratch,
}) => {
  const [listItems, setListItems] = useState(list.items);
  const [isHidden, setIsHidden] = useState(list.hidden ?? false);
  const [enableListItemMotion, setEnableListItemMotion] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setEnableListItemMotion(false);
    }, 1000);
  }, []);

  const toast = useToast();
  const navigate = useNavigate();

  const deleteDialogDisclosure = useDisclosure();
  const spotifyDisclosure = useDisclosure();
  const discardChangesDisclosure = useDisclosure();
  const onRefresh = useListRefresh(
    list.slug,
    () => {
      setIsEditing(false);
      navigate(`/me/${list.slug}`);
    },
    list.integrationProviderSlug
  );
  const onDelete = useDeleteListToast(list, () => setIsEditing(false));
  const onToggleHidden = useListToggleHidden(list);
  const onBack = (link: string) => {
    setEnableListItemMotion(true);
    setIsEditing(false);
    navigate(link);
  };

  const onMaybeDiscard = (e: any, link: string) => {
    e.preventDefault();
    if (!listItemsChanged(list.items, listItems)) {
      onBack(link);
    } else {
      discardChangesDisclosure.onOpen();
    }
  };

  const addToList = (item: ListItemType | Pin) => {
    if (listItems.find((i) => i.title === item.title)) {
      toast({
        title: "This item is already in your list!",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    } else if (
      listItems.length >= 5 &&
      !["carter", "noah2"].includes(user.username)
    ) {
      toast({
        title: "Your list can only have 5 items!",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    } else {
      setListItems([...listItems, item as ListItemType]);
    }
  };

  const toggleEditModeAndSave = useUpdateListToast(list, listItems, () => {
    setIsEditing(false);
    setEnableListItemMotion(true);
    navigate(`/${user.username}/${list.slug}`);
  });

  const removeFromList = (i: ListItemType | Pin) => {
    const item = i as ListItemType;
    setListItems(
      listItems.filter((result) => {
        if (result._id && item._id) {
          return result._id !== item._id;
        } else {
          return (
            result.title + result.subtitle + result.imageURI !==
            item.title + item.subtitle + item.imageURI
          );
        }
      })
    );
  };

  const theme = getTheme(user.backgroundType);

  return (
    <>
      <Center width="100%" mt={"-5.5em"} transition="all 0.3s ease-in-out">
        <motion.div
          layoutId={`${user.username}-list-${list.slug}`}
          style={{ width: "100%" }}
          initial={{
            background:
              theme.name === "Pastel"
                ? stringToPastelColor(list.slug)
                : theme.cardBg,
          }}
          animate={{ backgroundColor: "white" }}
        >
          <Card
            p="0.5em 1.25em 1em 1.25em"
            pt={isHidden ? "4.1em" : "2em"}
            width="100%"
            minHeight="38em"
            minH={"103vh"}
            overflow="hidden"
            transition="all 0.3s ease-in-out"
            bg={"white"}
            backdropFilter="auto"
            backdropBlur={BACKDROP_BLUR}
          >
            <Collapse in={isHidden}>
              <Box
                bg={BACKGROUND_HIDDEN}
                p="1.25em 1em 0.5em 1em"
                pos="absolute"
                left={0}
                top="0.75em"
                width="100%"
                transition="all 0.3s ease-in-out"
              >
                <Icon as={FaEyeSlash} mr="7px" mb="-2px" />
                Hidden on profile
              </Box>
            </Collapse>
            <HStack spacing={3} pt={2} pb={1}>
              <HStack ml="-0.5em">
                <ActionButton
                  icon={FaChevronLeft}
                  width="auto"
                  aria-label="Back to list"
                  onClick={(e: any) => {
                    if (fromScratch) onMaybeDiscard(e, "/create/list");
                    else onMaybeDiscard(e, `/${user.username}/${list.slug}`);
                  }}
                  as="a"
                  href={
                    fromScratch
                      ? "/create/list"
                      : `/${user.username}/${list.slug}`
                  }
                />
                <ProfilePicture
                  src={user.profilePicture}
                  size={2}
                  disableClick
                />
                <VStack align="start" spacing={0}>
                  <Heading
                    fontSize="0.75em"
                    fontWeight={500}
                    color="topFive.black"
                  >
                    @{user.username}
                  </Heading>
                  <Heading fontSize="lg" color="topFive.black">
                    {list.name}
                  </Heading>
                </VStack>
              </HStack>
              <HStack
                spacing={3}
                position="absolute"
                right="1.25em"
                top={isHidden ? "4.6em" : "2.25em"}
                transition="all 0.3s ease-in-out"
              >
                {list.integrationProviderSlug === "spotify" &&
                  (list.updateAutomatically ? (
                    <UpdateSpotifyButton onClick={spotifyDisclosure.onOpen} />
                  ) : (
                    <Icon
                      as={FaSpotify}
                      boxSize="2em"
                      onClick={spotifyDisclosure.onOpen}
                      cursor="pointer"
                    />
                  ))}
                <ActionButton
                  icon={FaTrashCan}
                  onClick={deleteDialogDisclosure.onOpen}
                  color="black"
                  border={"2px solid black"}
                  padding={2}
                  transition="all 0.3s ease-in-out"
                />
                {!fromScratch && (
                  // because there is no list in DB yet
                  <ActionButton
                    icon={isHidden ? FaEye : FaEyeSlash}
                    onClick={() => setIsHidden(onToggleHidden(!isHidden))}
                    color="black"
                    border={"2px solid black"}
                    padding={2}
                    transition="all 0.3s ease-in-out"
                  />
                )}
                <ActionButton
                  icon={FaCheck}
                  onClick={toggleEditModeAndSave}
                  padding={2}
                  color="black"
                  border={"2px solid black"}
                />
              </HStack>
            </HStack>
            <SearchField
              handleAddItem={addToList}
              handleRemoveItem={removeFromList}
              listName={list.name}
              modalStartsOpen={fromScratch}
              existingItems={listItems}
            />
            <Box transition="all 0.3s ease-in-out">
              <SortableList
                items={listItems.map(
                  (item) => item._id ?? item.title + item.imageURI
                )}
                setItems={setListItems}
              >
                {listItems.map((item, i) => (
                  <>
                    <ListItem
                      listSlug={list.slug}
                      key={item._id ?? item.title + item.imageURI}
                      rank={i + 1}
                      item={item}
                      isEditing
                      allowDrag={true}
                      imageWidth={getListImageWidth(list.slug)}
                      editAction={{
                        icon: FaRegCircleXmark,
                        action: () => {
                          removeFromList(item);
                        },
                      }}
                      enableMotion={enableListItemMotion}
                    />
                  </>
                ))}
              </SortableList>
            </Box>
          </Card>
        </motion.div>
      </Center>
      <ListDialogs
        onMyProfile
        list={list}
        spotifyDisclosure={spotifyDisclosure}
        deleteDisclosure={deleteDialogDisclosure}
        discardDisclosure={discardChangesDisclosure}
        onDelete={onDelete}
        onRefresh={onRefresh}
        onDiscardChanges={() => {
          onBack(
            fromScratch ? "/create/list" : `/${user.username}/${list.slug}`
          );
        }}
      />
    </>
  );
};

export const useDeleteListToast = (
  list: UserList,
  closeEditMode: () => void
) => {
  const navigate = useNavigate();
  const toast = useToast();
  const deleteList = useDeleteList(list.slug);
  return () => {
    const deletePromise = deleteList.mutateAsync();
    toast.promise(deletePromise, {
      success: (res) => {
        closeEditMode();
        navigate("/me");
        return { title: "List deleted" };
      },
      error: { title: "Error", description: "Error deleting list" },
      loading: { title: "Deleting..." },
    });
  };
};

export const useListToggleHidden = (list: UserList) => {
  const toast = useToast();
  const updateList = useUpdateList();
  return (hidden: boolean) => {
    const hidePromise = updateList.mutateAsync({
      slug: list.slug,
      hidden: hidden,
    });
    toast.promise(hidePromise, {
      success: (res) => {
        if (!res) {
          return {
            title: `Error ${hidden ? "hiding" : "showing"} list`,
            status: "error",
          };
        }
        return { title: `List ${hidden ? "hidden" : "shown"} on profile` };
      },
      error: (err) => {
        return {
          title: "Error",
          description: `Error ${hidden ? "hiding" : "showing"} list`,
        };
      },
      loading: { title: "Loading..." },
    });
    return hidden;
  };
};

export const useListRefresh = (
  listSlug: string,
  closeEditMode: () => void,
  integrationProviderSlug?: string
) => {
  const toast = useToast();

  const refreshIntegration = useRefreshList(
    listSlug,
    integrationProviderSlug ?? ""
  );
  if (!integrationProviderSlug) {
    return () => {};
  }
  return () => {
    if (!integrationProviderSlug) {
      return;
    }
    const newListPromise = refreshIntegration.mutateAsync();
    toast.promise(newListPromise, {
      success: (res: any) => {
        closeEditMode();
        return { title: "List refreshed!", duration: 3000, isClosable: true };
      },
      error: { title: "Error", description: "Error refreshing list" },
      loading: { title: "Loading..." },
    });
  };
};

export const useUpdateListToast = (
  oldList: UserList,
  newListItems: ListItemType[],
  closeEditMode: () => void
) => {
  const toast = useToast();
  const updateList = useUpdateList();
  return () => {
    console.log(
      oldList.items.map((i) => `${i.title}-${i.rating}-${i.comment}`)
    );
    console.log(newListItems.map((i) => `${i.title}-${i.rating}-${i.comment}`));
    if (!listItemsChanged(oldList.items, newListItems)) {
      closeEditMode();
      return;
    } else {
      const updatePromise = updateList.mutateAsync({
        slug: oldList.slug,
        items: newListItems,
      });
      toast.promise(updatePromise, {
        success: (res) => {
          closeEditMode();
          return {
            title: "List saved!",
            duration: 3000,
            isClosable: true,
          };
        },
        error: (e: any) => {
          return { title: "Error", description: e.response?.data?.error };
        },
        loading: { title: "Saving..." },
      });
    }
  };
};

export default EditList;
