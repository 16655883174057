import {
  Alert,
  AlertIcon,
  Button,
  Center,
  FormControl,
  Heading,
  Input,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { PasswordInput } from "src/components/Forms";
import { Header, Logo } from "src/components/Header/Header";
import PodiumsCard from "src/components/PodiumsCard";
import { passwordReset } from "src/networking";

export const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const params = new URLSearchParams(window.location.search);
  const nonce = params.get("nonce") ?? undefined;
  const account = params.get("account") ?? undefined;

  const [emailOrUsername, setEmailOrUsername] = useState(account);
  const [newPassword, setNewPassword] = useState("");

  const sendPasswordResetEmail = async () => {
    setLoading(true);
    const res = await passwordReset({ value: emailOrUsername });
    setLoading(false);
    setMessage(res.message);
    setSuccess(res.success);
  };

  const resetPassword = async () => {
    setLoading(true);
    const res = await passwordReset({ nonce, newPassword });
    setLoading(false);
    setMessage(res.message);
    setSuccess(res.success);
  };

  return (
    <>
      <Header />
      <Center
        width="100%"
        mx="auto"
        mt={8}
        minH="80vh"
        justifyContent="center"
        px="1em"
      >
        <VStack width="100%" spacing={4}>
          <Logo height="60px" />
          <PodiumsCard p="2em">
            <VStack>
              <Heading textAlign="center" mb={2}>
                Reset Password
              </Heading>
              {message !== "" ? (
                <Alert status={success ? "success" : "error"} borderRadius="md">
                  <AlertIcon />
                  {message}
                </Alert>
              ) : nonce ? (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    resetPassword();
                  }}
                >
                  <VStack spacing={4} w="100%">
                    <PasswordInput
                      password={newPassword}
                      setPassword={setNewPassword}
                      autocomplete="new-password"
                      label="New password"
                    />

                    <Button
                      w="100%"
                      variant="dark"
                      type="submit"
                      isLoading={loading}
                    >
                      Save Password
                    </Button>
                  </VStack>
                </form>
              ) : (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    sendPasswordResetEmail();
                  }}
                >
                  <VStack spacing={4} w="100%">
                    <FormControl>
                      <Input
                        type="text"
                        value={emailOrUsername}
                        onChange={(e) => setEmailOrUsername(e.target.value)}
                        placeholder="Username or email"
                        required
                      />
                    </FormControl>

                    <Button
                      w="100%"
                      variant="dark"
                      type="submit"
                      isLoading={loading}
                    >
                      Send reset email
                    </Button>
                  </VStack>
                </form>
              )}
              {message === "Password saved!" && (
                <Button as={RouterLink} to="/login">
                  Go to Login
                </Button>
              )}
            </VStack>
          </PodiumsCard>
        </VStack>
      </Center>
    </>
  );
};
export default ResetPassword;
