import {
  Button,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { MdIosShare } from "react-icons/md";
import { TbShare2 } from "react-icons/tb";
import { errorToast } from "src/utils";

interface LinkButtonProps {
  username: string;
}

export const ShareButton: React.FC<LinkButtonProps> = ({ username }) => {
  const [copiedOpen, setCopiedOpen] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const copyButtonRef = useRef<HTMLButtonElement | null>(null);

  // const queryParams = new URLSearchParams();
  // queryParams.append("utm_source", "profile_share");
  // queryParams.append("utm_medium", "copy_link");

  const link = "https://podiu.ms/" + username;

  const handleCopy = () => {
    navigator.clipboard.writeText(link);
    setCopiedOpen(true);
    setTimeout(() => setCopiedOpen(false), 2000);
    copyButtonRef.current?.focus();
  };

  const handleShare = async () => {
    try {
      await navigator.share({ url: link });
    } catch (err) {
      if (
        (err instanceof DOMException && !err.ABORT_ERR) ||
        err instanceof TypeError
      ) {
        errorToast("Error sharing link", toast);
      }
    }
  };

  return (
    <>
      <IconButton
        aria-label="Share"
        onClick={onOpen}
        size="2em"
        fontSize="24px"
        bg="none"
        ml="8px"
        mr="4px"
        mt="-3px"
        _hover={{ cursor: "pointer" }}
        as={TbShare2}
      />
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent mx="0.5em" borderRadius="xl">
          <ModalHeader p="1.5em 1em 0.5em 1em" color="topFive.black">
            Share your Podiums link:
          </ModalHeader>
          <ModalCloseButton size="lg" />
          <ModalBody px="1em" pb="2em">
            <Tooltip isOpen={copiedOpen} label="Copied!" placement="top">
              <InputGroup
                size="md"
                position="relative"
                border="1px solid gray"
                borderRadius="6px"
              >
                <Input
                  value={link.split("?")[0]}
                  readOnly
                  onClick={handleCopy}
                  border="none"
                  _focus={{ border: "none" }}
                />
                <InputRightElement width="auto">
                  <Button
                    mr="4px"
                    onClick={handleCopy}
                    h="32px"
                    ref={copyButtonRef}
                  >
                    Copy
                  </Button>
                  <IconButton
                    h="32px"
                    size="lg"
                    fontSize="22px"
                    aria-label="Share"
                    icon={<Icon as={MdIosShare}></Icon>}
                    onClick={handleShare}
                    mr="4px"
                  ></IconButton>
                </InputRightElement>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    right: "50px",
                    bottom: 0,
                    left: "calc(100% - 150px)",
                    background: "linear-gradient(to right, transparent, white)",
                    zIndex: 1,
                  }}
                />
              </InputGroup>
            </Tooltip>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
