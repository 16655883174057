import {
  Box,
  Button,
  HStack,
  Icon,
  Link,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import AppleLogin from "react-apple-login";
import {
  FaArrowTurnDown,
  FaGoogle,
  FaMobileScreen,
  FaSpotify,
  FaUser,
} from "react-icons/fa6";
import { Link as RouterLink } from "react-router-dom";
import { PodiumsDialog } from "../PodiumsDialog";

const DialogContent: React.FC<{ provider: string }> = ({ provider }) => {
  return (
    <VStack alignItems="start" spacing={4} mt="-1em">
      <Text>
        Sign in with {provider} is not supported on the Instagram browser. You
        can either:
      </Text>
      <VStack
        bg="gray.100"
        padding={4}
        borderRadius="lg"
        spacing={4}
        alignItems="start"
      >
        <HStack spacing={4}>
          <Icon boxSize="1.3em" as={FaMobileScreen} />
          <Text>
            Go to{" "}
            <Link
              as={RouterLink}
              to="https://podiu.ms"
              textDecoration="underline"
            >
              podiu.ms
            </Link>{" "}
            in your phone's browser (like Safari).
          </Text>
        </HStack>
        <HStack spacing={4}>
          <Icon boxSize="1.3em" as={FaUser} />
          <Text>Or, you can use a different login method.</Text>
        </HStack>
      </VStack>
    </VStack>
  );
};

const RecentText = () => {
  return (
    <Text py="10px" color="#fff" fontSize="14px">
      <Icon
        transform="rotate(180deg)"
        mr="6px"
        mt="-2px"
        as={FaArrowTurnDown}
      />
      You used this recently
    </Text>
  );
};

export const GoogleLoginButton: React.FC<{ isRecent?: boolean }> = ({
  isRecent,
}) => {
  var ua = navigator.userAgent || navigator.vendor || (window as any).opera;
  var isInstagram = ua.indexOf("Instagram") > -1 ? true : false;
  const disclosure = useDisclosure();
  return (
    <Box
      w="100%"
      borderRadius="22px"
      borderWidth={1}
      borderColor="#fff"
      display="flex"
      flexDir="column"
      alignItems="center"
    >
      <Button
        w="100%"
        leftIcon={<Icon as={FaGoogle} />}
        variant="light"
        as={isInstagram ? undefined : RouterLink}
        to={process.env.REACT_APP_SERVER_URL + "/auth/google"}
        onClick={isInstagram ? disclosure.onOpen : undefined}
        borderRadius="full"
      >
        Continue with Google
      </Button>
      <PodiumsDialog
        disclosure={disclosure}
        title="Oops..."
        content={<DialogContent provider="Google" />}
        cancelText="OK"
      />
      {isRecent && <RecentText />}
    </Box>
  );
};

export const SpotifyLoginButton: React.FC<{ isRecent?: boolean }> = ({
  isRecent,
}) => {
  var ua = navigator.userAgent || navigator.vendor || (window as any).opera;
  var isInstagram = ua.indexOf("Instagram") > -1 ? true : false;
  const disclosure = useDisclosure();
  return (
    <Box
      w="100%"
      borderRadius="22px"
      borderWidth={1}
      borderColor="#1ED760"
      display="flex"
      flexDir="column"
      alignItems="center"
    >
      <Button
        w="100%"
        height="40px"
        leftIcon={<Icon as={FaSpotify} color="white" />}
        color="#ffffff"
        backgroundColor="#1DB954"
        borderRadius="full"
        border="2px solid #1DB954"
        _hover={{ backgroundColor: "#1ED760", borderColor: "#1ED760" }}
        as={isInstagram ? undefined : RouterLink}
        to={process.env.REACT_APP_SERVER_URL + "/auth/spotify"}
        onClick={isInstagram ? disclosure.onOpen : undefined}
      >
        Continue with Spotify
      </Button>
      <PodiumsDialog
        disclosure={disclosure}
        title="Oops..."
        content={<DialogContent provider="Spotify" />}
        cancelText="OK"
      />
      {isRecent && <RecentText />}
    </Box>
  );
};

export const AppleLoginButton = () => {
  return (
    <Box
      w="100%"
      bg="white"
      borderRadius="full"
      display="flex"
      justifyContent="center"
      alignItems="center"
      cursor="pointer"
    >
      <AppleLogin
        clientId="com.podiums.app" // Your Service ID from Apple Developer
        redirectURI="https://api.podiu.ms/callbacks/apple"
        // responseType="code" // Request 'code' for backend validation
        // responseMode="query" // Use 'query' for web apps
        // usePopup={true} // Opens Apple sign-in in a popup
        designProp={{
          locale: "en_US",
          height: 40,
          width: 350,
          border_radius: 50,
          color: "white", // 'black' or 'white'
          border: false,
          type: "continue",
        }}
      />
    </Box>
  );
};
