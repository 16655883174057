import { extendTheme } from "@chakra-ui/react";
import type { StyleFunctionProps } from "@chakra-ui/styled-system";
import { BACKGROUND_DARK_GRADIENT_COLORED } from "./constants";

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-24px)",
};

export const colorBorderVariant = {
  position: "relative",
  color: "white",
  padding: "1em 2em",
  background:
    "linear-gradient(144deg, rgba(255,253,125,1) 0%, rgba(255,182,215,1) 31%, rgba(91,154,255,1) 100%)",
  borderRadius: "50px",
  backgroundClip: "border-box",
  _before: {
    content: `""`,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: BACKGROUND_DARK_GRADIENT_COLORED,
    borderRadius: "50px", // Match the same border-radius
    margin: "2px", // Adjust based on the border thickness
    zIndex: -1,
  },
  _after: {
    content: `""`,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: "50px", // Match the same border-radius
    margin: "2px", // Adjust based on the border thickness
    background:
      "linear-gradient(144deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.95) 10%, rgba(0, 0, 0, 0.9) 20%, rgba(0, 0, 0, 0.85) 30%, rgba(0, 0, 0, 0.8) 40%, rgba(0, 0, 0, 0.75) 50%, rgba(0, 0, 0, 0) 100%)",
    zIndex: -1,
    transition: "background 0.3s",
  },
  _hover: {
    opacity: 0.8,
  },
  zIndex: 1,
};

const theme = extendTheme({
  // fonts: {
  //   heading: `'Kanit', sans-serif`,
  // },
  styles: {
    global: (props: StyleFunctionProps) => ({
      body: {
        bg: "#000",
        // color: "#fff",
      },
    }),
  },
  colors: {
    topFive: {
      primary: "#191F22",
      primaryHover: "#BAAABF",
      secondary: "#2d83b5", // "#70BAE5", //"#fe938c"
      white: "#ffffff",
      gray: {
        light: "#d4d5d9",
        lightHover: "#bec0c5",
        mid: "#bfbfbf",
        dark: "#4F5762",
      },
      black: "#191F22",
      red: "#EE6352",
      yellow: "#ffcf70",
      green: "#59cd90",
    },
  },
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            // label: {
            //   ...activeLabelStyles,
            // },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
              {
                ...activeLabelStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              backgroundColor: "white",
              borderRadius: "4px",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top",
              ...activeLabelStyles,
            },
            _readOnly: {
              label: {
                color: "white",
              },
            },
          },
        },
      },
    },
    Button: {
      variants: {
        light: {
          bg: "#fff",
          color: "topFive.primary",
          _hover: { opacity: 0.7 },
        },
        dark: colorBorderVariant,
        outline: {
          bg: "transparent",
          color: "white",
          border: "2px solid",
          borderColor: "white",
          _hover: { bg: "rgba(255,255,255,0.3)", color: "white" },
        },
      },
    },
    Input: {
      variants: {
        filled: {
          field: {
            color: "black",
            backgroundColor: "#fff",
            paddingLeft: "12px",
            _hover: {
              backgroundColor: "#fff",
            },
            _focusVisible: {
              backgroundColor: "#fff",
              border: "none",
              borderColor: "none",
            },
          },
        },
      },
      defaultProps: {
        variant: "filled",
      },
      baseStyle: {
        field: {
          color: "black",
        },
      },
    },
  },
});

export default theme;
