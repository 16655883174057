import { UseDisclosureReturn, useToast } from "@chakra-ui/react";
import React from "react";
import { FaRegGem } from "react-icons/fa";
import { FaCircleXmark, FaSpotify, FaTrashCan } from "react-icons/fa6";
import { IoSparkles } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { PodiumsDialog } from "src/components/PodiumsDialog";
import { useUpdateList } from "src/state/userQueries";
import { UserList } from "src/types/list";

export const LtdEditionDialog: React.FC<{
  disclosure: UseDisclosureReturn;
}> = ({ disclosure }) => {
  return (
    <PodiumsDialog
      title="Limited Edition List"
      titleIcon={FaRegGem}
      message="This list is limited edition, and was only temporarily available to create."
      disclosure={disclosure}
    />
  );
};

export const SpotifyDialog: React.FC<{
  listSlug: string;
  disclosure: UseDisclosureReturn;
  onMyProfile: boolean;
  isUpdatingAutomatically?: boolean;
}> = ({ listSlug, disclosure, onMyProfile, isUpdatingAutomatically }) => {
  const toast = useToast();

  const updateList = useUpdateList();
  const navigate = useNavigate();

  const toggleUpdateAutomatically = () => {
    const update = updateList.mutateAsync({
      slug: listSlug,
      updateAutomatically: !isUpdatingAutomatically,
    });

    toast.promise(update, {
      loading: { title: `Updating...` },
      success: (res) => {
        navigate(`/me/${listSlug}`);
        return {
          title: `List will ${
            isUpdatingAutomatically ? "no longer" : "now"
          } update daily.`,
        };
      },
      error: { title: "Failed to update list." },
    });
  };

  const message = isUpdatingAutomatically
    ? "This list auto-updates daily from Spotify."
    : "This list was imported from Spotify." +
      (onMyProfile
        ? " You can enable Auto-Update, which will update the list with your Spotify listening daily."
        : "");

  return (
    <PodiumsDialog
      title="Spotify List"
      message={message}
      titleIcon={FaSpotify}
      disclosure={disclosure}
      cancelText="OK"
      buttons={
        onMyProfile
          ? [
              {
                text: isUpdatingAutomatically ? "Stop Updating" : "Auto-Update",
                onClick: toggleUpdateAutomatically,
                icon: isUpdatingAutomatically ? FaCircleXmark : IoSparkles,
                colorScheme: isUpdatingAutomatically ? "red" : "green",
              },
            ]
          : undefined
      }
    />
  );
};

export const DeleteListDialog: React.FC<{
  deleteDisclosure: UseDisclosureReturn;
  onDelete: () => void;
  listName: string;
}> = ({ deleteDisclosure, onDelete, listName }) => {
  return (
    <PodiumsDialog
      disclosure={deleteDisclosure}
      title="Delete List"
      message={`Are you sure you want to delete ${listName}?`}
      buttons={[
        {
          text: "Delete",
          onClick: onDelete,
          icon: FaTrashCan,
          colorScheme: "red",
        },
      ]}
      cancelText="Cancel"
    />
  );
};

interface ListDialogsProps {
  onMyProfile: boolean;
  list: UserList;
  deleteDisclosure: UseDisclosureReturn;
  discardDisclosure?: UseDisclosureReturn;
  spotifyDisclosure?: UseDisclosureReturn;
  limitedDisclosure?: UseDisclosureReturn;
  onRefresh: () => void;
  onDelete: () => void;
  onDiscardChanges?: () => void;
}

const ListDialogs: React.FC<ListDialogsProps> = ({
  onMyProfile,
  list,
  deleteDisclosure,
  discardDisclosure,
  spotifyDisclosure,
  limitedDisclosure,
  onDelete,
  onRefresh,
  onDiscardChanges,
}) => {
  return (
    <>
      {/* Refresh List dialog */}
      {/* <PodiumsDialog
        disclosure={refreshDisclosure}
        title="Refresh List"
        message="Are you sure you want to refresh this list from Spotify? This will remove any changes you've made."
        buttons={[
          {
            text: "Refresh",
            onClick: onRefresh,
            icon: FaSpotify,
            colorScheme: "green",
          },
        ]}
        cancelText="Cancel"
      /> */}
      {/* Delete List dialog */}
      <DeleteListDialog
        onDelete={onDelete}
        deleteDisclosure={deleteDisclosure}
        listName={list.name}
      />
      {/* Hidden items dialog */}
      {/* <PodiumsDialog
        disclosure={hiddenDisclosure}
        title="Hidden items"
        message="You can add as many songs as you want to this list, but only the top 5 are shown on your public profile."
      /> */}
      {/* Discard Changes dialog */}
      {discardDisclosure && onDiscardChanges && (
        <PodiumsDialog
          disclosure={discardDisclosure}
          title="Discard changes"
          message="Are you sure you want to leave without saving?"
          buttons={[
            {
              text: "Discard",
              onClick: onDiscardChanges,
              icon: FaTrashCan,
              colorScheme: "red",
            },
          ]}
          cancelText="Cancel"
        />
      )}
      {limitedDisclosure && <LtdEditionDialog disclosure={limitedDisclosure} />}
      {spotifyDisclosure && (
        <SpotifyDialog
          disclosure={spotifyDisclosure}
          listSlug={list.slug}
          onMyProfile={onMyProfile}
          isUpdatingAutomatically={list.updateAutomatically}
        />
      )}
    </>
  );
};

export default ListDialogs;
