import { Center, Heading, VStack } from "@chakra-ui/react";
import React from "react";
import { Header } from "src/components/Header/Header";
import ListDiff from "src/components/Lists/View/ListDiff";
import PodiumsCard from "src/components/PodiumsCard";
import { ShimmerListDiffs } from "src/components/Shimmer";
import GradientBackground from "src/components/User/UserBackground";
import { UserProfileHeader } from "src/components/User/UserProfileHeader";
import { useCurrentUser } from "src/state/userQueries";

const MyListDiffs: React.FC = () => {
  const { data: user, isError, isLoading } = useCurrentUser();

  if (isLoading) {
    return (
      <>
        <Header />
        <ShimmerListDiffs />
      </>
    );
  } else if (!user || isError) {
    return <></>;
  } else {
    return (
      <GradientBackground themeSlug={user.backgroundType}>
        <Header />
        <UserProfileHeader
          user={user}
          onMyProfile={true}
          backURL={`/${user.username}`}
          isLoggedIn
        />
        <Center width="100%" p="1em" position="relative">
          <VStack position="relative" spacing={4} width="100%">
            <Heading color="white" whiteSpace="nowrap">
              My Activity
            </Heading>
            {user.diffs.length === 0 ? (
              <PodiumsCard>
                <Heading size="4xl">😥</Heading>
                <Heading color="topFive.black">
                  {user.username} has no lists!
                </Heading>
              </PodiumsCard>
            ) : (
              user.diffs.map((d) => <ListDiff diff={d} onMyProfile />)
            )}
          </VStack>
        </Center>
      </GradientBackground>
    );
  }
};

export default MyListDiffs;
