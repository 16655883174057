import { VStack } from "@chakra-ui/react";
import React from "react";

import { TALL_IMAGE_LISTS } from "src/constants";
import { UserList } from "src/types/list";
import { UserTheme } from "src/types/userThemes";
import ListButtton from "./ListButton";

const OneColumnListOfLists: React.FC<{
  username: string;
  theme: UserTheme;
  lists: UserList[];
  setLists: (l: UserList[]) => void;
}> = ({ username, lists, setLists, theme }) => {
  return (
    <VStack width="100%" maxW="450px" borderRadius="xl" spacing={3}>
      {lists.map((list) => (
        <ListButtton
          username={username}
          userList={list}
          key={list.slug}
          tallImages={TALL_IMAGE_LISTS.includes(list.slug)}
          theme={theme}
        />
      ))}
    </VStack>
  );
};
export default OneColumnListOfLists;
