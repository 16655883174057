import { Box, VStack } from "@chakra-ui/react";
import {
  closestCorners,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import React from "react";
import { UserList } from "src/types/list";
import { UserTheme } from "src/types/userThemes";
import SortableListPreviewButton from "./SortableListPreviewButton";

interface EditingOneColumnProps {
  lists: UserList[];
  setLists: React.Dispatch<React.SetStateAction<UserList[]>>;
  theme: UserTheme;
}

const EditingOneColumn: React.FC<EditingOneColumnProps> = ({
  lists,
  setLists,
  theme,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) return;

    const id = active.id as string;
    const overId = over.id as string;
    const activeIndex = lists.findIndex((item) => item.slug === id);
    const overIndex = lists.findIndex((item) => item.slug === overId);

    if (activeIndex !== overIndex) {
      setLists((lists) => arrayMove(lists, activeIndex, overIndex));
    }
  };

  const onDelete = (listSlug: string) => {
    setLists(lists.filter((l) => l.slug !== listSlug));
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragEnd={handleDragEnd}
    >
      <Box w="100%" minH="200px" bg="transparent" borderRadius="md">
        <SortableContext
          items={lists.map((item) => item.slug)}
          strategy={verticalListSortingStrategy}
        >
          <VStack w="100%" spacing={2}>
            {lists.map((list) => (
              <SortableListPreviewButton
                key={list.slug}
                id={list.slug}
                list={list}
                onDelete={onDelete}
                theme={theme}
              />
            ))}
          </VStack>
        </SortableContext>
      </Box>
    </DndContext>
  );
};

export default EditingOneColumn;
