import {
  Box,
  Heading,
  HStack,
  Icon,
  Image,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { FaPlusCircle, FaSpotify } from "react-icons/fa";
import { FaCaretDown, FaCaretUp } from "react-icons/fa6";
import { SlRefresh } from "react-icons/sl";
import { Link as RouterLink } from "react-router-dom";
import { TALL_IMAGE_LISTS } from "src/constants";
import { ListDiff as ListDiffType } from "src/types/user";
import { listNameToSlug, relativeDateShort } from "src/utils";
import PodiumsCard from "../../PodiumsCard";
import { ProfilePicture } from "../../User/UserProfileHeader";

const ListDiff: React.FC<{ diff: ListDiffType; onMyProfile?: boolean }> = ({
  diff,
  onMyProfile,
}) => {
  const ListLink = () => (
    <Link
      as={RouterLink}
      to={`/${onMyProfile ? "me" : diff.user?.username}/${listNameToSlug(
        diff.listName
      )}`}
      fontWeight={700}
      color="topFive.primary"
    >
      {diff.listName}
    </Link>
  );

  return (
    <PodiumsCard align="start" overflow="hidden">
      <HStack width="100%" justify="space-between">
        {diff.user && !onMyProfile ? (
          <HStack>
            <ProfilePicture size={1.5} src={diff.user.profilePicture} />
            <Heading fontSize="1em" mb="0.1em" fontWeight={500}>
              <Link
                as={RouterLink}
                to={`/${diff.user.username}`}
                fontWeight={600}
                color="topFive.primary"
              >
                @{diff.user.username}
              </Link>{" "}
              {diff.diffItems.length > 0 ? "updated" : "created"} <ListLink />{" "}
            </Heading>
            {diff.autoUpdate && (
              <Box pos="relative" display="inline" mt="-26px" ml="-4px">
                <Icon
                  as={SlRefresh}
                  pos="absolute"
                  boxSize="28px"
                  color="black"
                />
                <Icon
                  as={FaSpotify}
                  pos="absolute"
                  boxSize="14px"
                  top="7px"
                  left="7px"
                  color="black"
                />
              </Box>
            )}
          </HStack>
        ) : (
          <Heading fontSize="1em" fontWeight={400}>
            <ListLink /> was {diff.diffItems.length > 0 ? "updated" : "created"}
          </Heading>
        )}
        <Text mt="-3px">{relativeDateShort(diff.updatedAt)}</Text>
      </HStack>
      {diff.diffItems.length > 0 && (
        <VStack width="100%" mt="0.75em">
          {diff.diffItems.map((item, index) => {
            const tallImage = TALL_IMAGE_LISTS.includes(
              listNameToSlug(diff.listName)
            );
            return (
              <HStack width="100%" justify="space-between" key={index}>
                <HStack spacing={3}>
                  <Image
                    src={item.value.imageURI}
                    alt={item.value.title}
                    width={tallImage ? "1.11em" : "1.67em"}
                    height={"1.67em"}
                    objectFit="cover"
                    boxShadow="0px 14px 20px 0px rgba(0,0,0,0.1)"
                    background="white"
                    borderRadius="4px"
                  />
                  <Heading
                    fontSize="1em"
                    fontWeight={500}
                    // color="gray.700"
                  >
                    {item.value.title}
                  </Heading>
                </HStack>
                <HStack
                  color={item.change <= 0 ? "green" : "red"}
                  spacing={0.5}
                >
                  <Icon
                    as={
                      item.change === 0
                        ? FaPlusCircle
                        : item.change < 0
                        ? FaCaretUp
                        : FaCaretDown
                    }
                  />
                  <Text>
                    {item.change === 0 ? null : Math.abs(item.change)}
                  </Text>
                </HStack>
              </HStack>
            );
          })}
        </VStack>
      )}
    </PodiumsCard>
  );
};

export default ListDiff;
