import { BACKGROUND_DARK_GRADIENT, BACKGROUND_FADED } from "../constants";

export interface UserTheme {
  name: string;
  cardBg: string; // default: white
  cardBorderColor: string; // default: none
  cardShadow: string; // default: md
  logoColor: string; // default: white
  textColor: string; // default: topFive.black
  navButtonBg: string; // default: white
  textOnBackgroundColor: string; // default: topFive.black
}

const defaultTheme: {
  cardBg: string;
  cardBorderColor: string;
  cardShadow: string;
  logoColor: string;
  textColor: string;
  navButtonBg: string;
  textOnBackgroundColor: string;
} = {
  cardBg: "white",
  cardBorderColor: "none",
  cardShadow: "none",
  logoColor: "black",
  textColor: "topFive.black",
  navButtonBg: "white",
  textOnBackgroundColor: "topFive.black",
};

const themes: { [key: string]: UserTheme } = {
  black: {
    ...defaultTheme,
    name: "Black",
    logoColor: "",
    textColor: "white",
    cardBg: BACKGROUND_DARK_GRADIENT,
    textOnBackgroundColor: "white",
  },
  white: {
    ...defaultTheme,
    name: "White",
    cardBg: "white",
    cardShadow: "md",
    logoColor: "black",
  },
  midnight: {
    ...defaultTheme,
    name: "Midnight",
    logoColor: "white-faded",
    textColor: "white",
    cardBg: "topFive.black",
    textOnBackgroundColor: "white",
    navButtonBg: BACKGROUND_FADED,
  },
  pastel: {
    ...defaultTheme,
    name: "Pastel",
    cardBg: "pastel", // special
    cardBorderColor: "topFive.black",
    logoColor: "black",
  },
  "party-gradient": {
    ...defaultTheme,
    name: "Violet",
    cardBg: BACKGROUND_FADED,
    logoColor: "white-faded",
    navButtonBg: BACKGROUND_FADED,
    textOnBackgroundColor: "white",
  },
  "blue-gradient": {
    ...defaultTheme,
    name: "Ocean",
    cardBg: BACKGROUND_FADED,
    logoColor: "white-faded",
    navButtonBg: BACKGROUND_FADED,
    textOnBackgroundColor: "white",
  },
  "cotton-candy-gradient": {
    ...defaultTheme,
    name: "Cotton Candy",
    cardBg: BACKGROUND_FADED,
    logoColor: "white-faded",
    textColor: "topFive.black",
    navButtonBg: BACKGROUND_FADED,
  },
  "pink-lemonade-gradient": {
    ...defaultTheme,
    name: "Pink Lemonade",
    cardBg: BACKGROUND_FADED,
    logoColor: "white-faded",
    textColor: "topFive.black",
    navButtonBg: BACKGROUND_FADED,
  },
  "party-gradient-faded": {
    ...defaultTheme,
    name: "Hazy Purple",
    cardBg: BACKGROUND_FADED,
    logoColor: "white-faded",
    navButtonBg: BACKGROUND_FADED,
  },
  coffee: {
    ...defaultTheme,
    name: "Coffee",
    cardBg: BACKGROUND_FADED,
    logoColor: "white-faded",
    navButtonBg: BACKGROUND_FADED,
    textOnBackgroundColor: "white",
  },
  sage: {
    ...defaultTheme,
    name: "Sage",
    cardBg: BACKGROUND_FADED,
    logoColor: "white-faded",
    navButtonBg: BACKGROUND_FADED,
    textOnBackgroundColor: "white",
  },
};

export const ALL_THEMES: string[] = Object.keys(themes);

export const getTheme = (themeSlug?: string): UserTheme => {
  return themes[themeSlug ?? ""] ?? themes["black"];
};

export default themes;
