import {
  Box,
  Button,
  Center,
  Collapse,
  HStack,
  Heading,
  Icon,
  Slide,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { FaRegGem } from "react-icons/fa";
import {
  FaChevronLeft,
  FaEyeSlash,
  FaList,
  FaPencil,
  FaSpotify,
  FaTableCellsLarge,
} from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { ActionButton } from "src/components/Buttons";
import {
  BACKDROP_BLUR,
  BACKGROUND_DARK_GRADIENT_COLORED,
  BACKGROUND_HIDDEN,
  BLACK_AFTER,
  BLACK_BEFORE,
  RATINGS_ENABLED_USERS,
  borderRadiusDisabledUsers,
} from "src/constants";
import { SanitizedOtherUser } from "src/types/user";
import { getTheme } from "src/types/userThemes";
import { relativeDate, stringToPastelColor } from "src/utils";
import { ListItem as ListItemType, UserList } from "../../../types/list";
import PodiumsCard from "../../PodiumsCard";
import { ProfilePicture } from "../../User/UserProfileHeader";
import {
  useDeleteListToast,
  useListRefresh,
  useUpdateListToast,
} from "../Edit/EditList";
import { getListImageWidth } from "../utils";
import ListDialogs from "../Utils/ListDialogs";
import { UpdateSpotifyButton } from "../Utils/ListIcon";
import ListGrid from "./ListGrid";
import ListItem from "./ListItem";
import RatingSlider from "./RatingSlider";

export const ItemsHiddenButton: React.FC<{ onOpen: () => void }> = ({
  onOpen,
}) => {
  return (
    <>
      <Button onClick={onOpen} pos="relative" bg="rgba(0,0,0,0)" height="2em">
        <HStack width="100%" justify="center">
          <Icon as={FaEyeSlash} mr="7px" />
          <Text>Hidden on profile</Text>
        </HStack>
      </Button>
    </>
  );
};

interface ListProps {
  user: SanitizedOtherUser;
  list: UserList;
  enterEditMode?: () => void;
  onMyProfile?: boolean;
}

const List: React.FC<ListProps> = ({
  user,
  list,
  enterEditMode,
  onMyProfile,
}) => {
  const [items, setItems] = useState(
    RATINGS_ENABLED_USERS.includes(user.username)
      ? list.items.sort((a, b) => (b.rating ?? 0) - (a.rating ?? 0))
      : list.items
  );
  const [isHidden, setIsHidden] = useState(list.hidden ?? false);
  const [isGrid, setIsGrid] = useState(
    localStorage.getItem("listGridMode") === "true"
  );

  const navigate = useNavigate();
  const deleteDialogDisclosure = useDisclosure();
  const spotifyDisclosure = useDisclosure();
  const limitedDisclosure = useDisclosure();
  const onRefresh = useListRefresh(
    list.slug,
    () => navigate(`/${user.username}/${list.slug}`),
    list.integrationProviderSlug
  );

  const onDelete = useDeleteListToast(list, () => {});
  const updateList = useUpdateListToast(list, items, () =>
    setItemBeingRated(null)
  );
  // const onToggleHidden = useListToggleHidden(list);

  const onToggleGrid = () => {
    if (isGrid) {
      setIsGrid(false);
      localStorage.setItem("listGridMode", "false");
    } else {
      setIsGrid(true);
      localStorage.setItem("listGridMode", "true");
    }
  };

  const onBackToProfile = () => {
    navigate(`/${user.username}`);
  };

  const imageWidth = getListImageWidth(list.slug);
  const isSpotify = list.integrationProviderSlug === "spotify";
  const isLimited = list.limitedEdition ?? false;

  const theme = getTheme(user.backgroundType);
  const [itemBeingRated, setItemBeingRated] = useState<ListItemType | null>(
    null
  );

  const updateRating = (itemId: string, newRating: number) => {
    if (itemBeingRated) {
      itemBeingRated.rating = newRating;
    }
    setItems((prevItems) => {
      const updatedItems = prevItems.map((item) =>
        item._id === itemId ? { ...item, rating: newRating } : item
      );
      // Sort by rating in descending order
      return [...updatedItems].sort(
        (a, b) => (b.rating ?? 0) - (a.rating ?? 0)
      );
    });
  };

  const updateComment = (itemId: string, newComment: string) => {
    if (itemBeingRated) {
      itemBeingRated.comment = newComment;
    }
    setItems((prevItems) => {
      return prevItems.map((item) =>
        item._id === itemId ? { ...item, comment: newComment } : item
      );
    });
  };

  return (
    <>
      <Center px={3} pb="1.5em" width="100%" color={theme?.textColor}>
        <VStack width="100%" spacing={0}>
          <motion.div
            layoutId={`${user.username}-list-${list.slug}`}
            style={{ width: "100%", maxWidth: "450px" }}
          >
            <PodiumsCard
              p={0}
              zIndex="2"
              boxShadow={theme.cardShadow ?? "md"}
              bg={
                theme.name === "Pastel"
                  ? stringToPastelColor(list.slug)
                  : theme.name === "Black"
                  ? BACKGROUND_DARK_GRADIENT_COLORED
                  : theme.cardBg
              }
              borderWidth={theme.cardBorderColor !== "none" ? "1px" : 0}
              borderColor={theme.cardBorderColor}
              backdropFilter="auto"
              backdropBlur={BACKDROP_BLUR}
              _before={theme.name === "Black" ? BLACK_BEFORE : undefined}
              _after={theme.name === "Black" ? BLACK_AFTER : undefined}
            >
              <Collapse in={isHidden} style={{ width: "100%" }}>
                <Box
                  bg={BACKGROUND_HIDDEN}
                  p="0.5em 1em"
                  width="100%"
                  borderTopRadius="xl"
                >
                  <Icon as={FaEyeSlash} mr="7px" mb="-2px" />
                  Hidden on profile
                </Box>
              </Collapse>
              <Box
                px="1.25em"
                pt={isHidden ? "0.5em" : "1em"}
                width="100%"
                pb="1em"
              >
                <HStack
                  justify="space-between"
                  align="center"
                  mb="0.75em"
                  ml="-0.5em"
                >
                  <HStack onClick={onBackToProfile} cursor="pointer">
                    <Icon
                      as={FaChevronLeft}
                      aria-label="Back to profile"
                      color={theme.textColor}
                    />
                    <ProfilePicture
                      src={user.profilePicture}
                      size={2}
                      disableClick
                    />
                    <VStack align="start" spacing={0}>
                      <Heading
                        fontSize="0.75em"
                        fontWeight={500}
                        color={theme.textColor}
                      >
                        @{user.username}
                      </Heading>
                      <Heading fontSize="lg" color={theme.textColor}>
                        {list.name}
                      </Heading>
                    </VStack>
                  </HStack>
                  <HStack spacing={3}>
                    {isSpotify &&
                      (list.updateAutomatically ? (
                        <UpdateSpotifyButton
                          onClick={spotifyDisclosure.onOpen}
                          textColor={theme?.textColor}
                        />
                      ) : (
                        <Icon
                          as={FaSpotify}
                          boxSize="2em"
                          onClick={spotifyDisclosure.onOpen}
                          cursor="pointer"
                          color={theme?.textColor}
                        />
                      ))}
                    {isLimited && (
                      <Icon
                        as={FaRegGem}
                        boxSize="1.75em"
                        onClick={limitedDisclosure.onOpen}
                        cursor="pointer"
                        color={theme?.textColor}
                      />
                    )}
                    {onMyProfile &&
                      enterEditMode && [
                        <ActionButton
                          key={1}
                          icon={isGrid ? FaList : FaTableCellsLarge}
                          onClick={onToggleGrid}
                          borderWidth="2px"
                          borderColor={theme?.textColor}
                          color={theme?.textColor}
                          padding={2}
                          transition="all 0.3s ease-in-out"
                        />,
                        <ActionButton
                          key={2}
                          icon={FaPencil}
                          onClick={enterEditMode}
                          color={theme?.textColor}
                          borderWidth="2px"
                          borderColor={theme?.textColor}
                          padding={2}
                          transition="all 0.3s ease-in-out"
                        />,
                      ]}
                  </HStack>
                </HStack>
                <Box width="100%">
                  <AnimatePresence mode="popLayout">
                    {isGrid && onMyProfile ? (
                      <ListGrid
                        listSlug={list.slug}
                        items={items}
                        borderRadius={
                          localStorage.getItem("borderRadiusDisabled") !==
                            "true" ||
                          !borderRadiusDisabledUsers.includes(user.username)
                        }
                        enableDetailModal
                      />
                    ) : (
                      items.map((item, i) => (
                        <motion.div
                          key={item._id}
                          // initial={{ opacity: 0, y: 50 }}
                          animate={{
                            opacity: 1,
                            y: 0,
                            transition: {
                              type: "spring",
                              stiffness: 300,
                              damping: 24,
                              mass: 0.8,
                            },
                          }}
                          // exit={{ opacity: 0, y: -50 }}
                          layout
                          style={{ width: "100%" }}
                        >
                          <ListItem
                            listSlug={list.slug}
                            key={i}
                            item={item}
                            rank={i + 1}
                            allowDrag={false}
                            imageWidth={imageWidth}
                            theme={theme}
                            onClick={() =>
                              RATINGS_ENABLED_USERS.includes(user.username) &&
                              onMyProfile
                                ? setItemBeingRated({ ...item })
                                : undefined
                            }
                            enableDetailModal={
                              !RATINGS_ENABLED_USERS.includes(user.username) ||
                              !onMyProfile
                            }
                            enableMotion={itemBeingRated === null}
                          />
                        </motion.div>
                      ))
                    )}
                  </AnimatePresence>
                </Box>
                {list.updatedAt && (
                  <Text color={theme?.textColor} opacity={0.8}>
                    updated {relativeDate(list.updatedAt)}
                  </Text>
                )}
              </Box>
            </PodiumsCard>
          </motion.div>
        </VStack>
      </Center>
      <Slide
        in={!!itemBeingRated}
        direction="bottom"
        style={{
          zIndex: 100,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {
          <RatingSlider
            rating={itemBeingRated?.rating ?? 7}
            onRatingChange={(v) => {
              updateRating(itemBeingRated?._id ?? "", v);
            }}
            comment={itemBeingRated?.comment ?? ""}
            onCommentChange={(v) => {
              updateComment(itemBeingRated?._id ?? "", v);
            }}
            itemName={itemBeingRated?.title ?? ""}
            onSave={updateList}
          />
        }
      </Slide>
      <ListDialogs
        onMyProfile={onMyProfile ?? false}
        list={list}
        deleteDisclosure={deleteDialogDisclosure}
        spotifyDisclosure={spotifyDisclosure}
        limitedDisclosure={isLimited ? limitedDisclosure : undefined}
        onDelete={onDelete}
        onRefresh={onRefresh}
      />
    </>
  );
};

export default List;
