import { Button, Icon, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";
import { FaPlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { BACKGROUND_FADED } from "src/constants";

export const FloatingCreateButton: React.FC<{
  isFirstList: boolean;
}> = ({ isFirstList }) => {
  const navigate = useNavigate();
  return (
    <motion.div layoutId="create-save-button">
      <Button
        flex={1}
        display="flex"
        flexDir="row"
        justifyContent="center"
        alignItems="center"
        aria-label="Create list"
        bg={BACKGROUND_FADED}
        backdropFilter="auto"
        backdropBlur="8px"
        boxShadow="0px 0px 15px rgba(0, 0, 0, 0.2)"
        borderRadius="full"
        gap="10px"
        height={isFirstList ? "50px" : "60px"}
        w={isFirstList ? "130px" : "60px"}
        position="fixed"
        bottom="20px"
        right="20px"
        onClick={() => {
          navigate("/create/list");
        }}
        cursor="pointer"
      >
        <Icon as={FaPlus} fontSize={isFirstList ? "24px" : "28px"} />
        {isFirstList && (
          <Text fontSize="18px" fontWeight={500}>
            Create
          </Text>
        )}
      </Button>
    </motion.div>
  );
};
