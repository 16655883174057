import {
  FormControl,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FaEye, FaEyeSlash, FaLock } from "react-icons/fa6";

export const PasswordInput: React.FC<{
  password: string;
  setPassword: (p: string) => void;
  disableShowPass?: boolean;
  label?: string;
  autofocus?: boolean;
  autocomplete?: string;
  borderColor?: string;
}> = ({
  password,
  setPassword,
  disableShowPass,
  label,
  autofocus,
  autocomplete,
  borderColor,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <FormControl>
      <InputGroup>
        <InputLeftAddon
          bg="white"
          pl="12px"
          pr={0}
          borderWidth="3px"
          borderColor={borderColor}
          borderRightWidth={0}
          transition="all 0.3s"
          color="black"
        >
          <Icon as={FaLock} />
        </InputLeftAddon>
        <Input
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder={label ?? "Password"}
          autoComplete={autocomplete ?? "current-password"}
          autoFocus={autofocus}
          required
          variant="filled"
          borderWidth="3px"
          borderColor={borderColor}
          borderLeftWidth={0}
          transition="all 0.3s"
          _focusVisible={{
            borderColor: borderColor,
            borderWidth: "3px",
            borderLeftWidth: 0,
          }}
        />
        {!disableShowPass && (
          <InputRightElement>
            <IconButton
              aria-label={showPassword ? "Hide password" : "Show password"}
              icon={showPassword ? <FaEyeSlash /> : <FaEye />}
              size="sm"
              onClick={() => setShowPassword(!showPassword)}
            />
          </InputRightElement>
        )}
      </InputGroup>
      {/* <FormLabel>{label ?? "Password"}</FormLabel> */}
    </FormControl>
  );
};
