import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";
import {
  getDiffs,
  getListOptions,
  getMovieDetails,
  getRecommendations,
  getShowDetails,
} from "../networking";

export const lists = createQueryKeys("list", {
  options: {
    queryKey: ["options"],
    queryFn: getListOptions,
  },
  recommendations: (listSlug: string) => ({
    queryKey: [listSlug],
    queryFn: () => getRecommendations(listSlug),
  }),
  diffs: {
    queryKey: null,
    queryFn: getDiffs,
  },
  movieDetails: (movieId: string) => ({
    queryKey: ["movieDetails", movieId],
    queryFn: () => getMovieDetails(movieId),
  }),
  showDetails: (showId: string) => ({
    queryKey: ["movieDetails", showId],
    queryFn: () => getShowDetails(showId),
  }),
});

export const useGetDiffs = () => {
  return useQuery({
    ...lists.diffs,
    staleTime: 1000 * 30, // 30 seconds
    retry: false,
  });
};

export const useGetListOptions = () => {
  return useQuery({ ...lists.options, staleTime: 1000 * 60 * 5, retry: false });
};

export const useGetRecommendations = (listSlug: string) => {
  return useQuery({
    ...lists.recommendations(listSlug),
    staleTime: 1000 * 60 * 5,
    retry: false,
  });
};

export const useGetMovieDetails = (movieId: string, enabled: boolean) => {
  return useQuery({
    ...lists.movieDetails(movieId),
    staleTime: 1000 * 60 * 5,
    retry: false,
    enabled,
  });
};

export const useGetShowDetails = (showId: string, enabled: boolean) => {
  return useQuery({
    ...lists.showDetails(showId),
    staleTime: 1000 * 60 * 5,
    retry: false,
    enabled,
  });
};