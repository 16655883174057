import { Box, HStack, Input, VStack } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { ActionButton } from "src/components/Buttons";

interface RatingSliderProps {
  rating: number;
  comment: string;
  onRatingChange: (value: number) => void;
  onCommentChange: (comment: string) => void;
  itemName: string;
  onSave: () => void;
}

interface Point {
  clientX: number;
  clientY: number;
}

const RatingSlider: React.FC<RatingSliderProps> = ({
  rating,
  comment,
  onRatingChange,
  onCommentChange,
  itemName,
  onSave,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [sliderRef, setSliderRef] = useState<SVGSVGElement | null>(null);

  // SVG dimensions and parameters
  const width = 300;
  const height = 170; // Increased from 150 to accommodate the full handle
  const radius = 120;
  const strokeWidth = 8;
  const handleRadius = strokeWidth * 1.5; // Define handle radius separately
  const min = 0;
  const max = 10;
  const step = 0.1;

  // Center point of the semicircle
  const cx = width / 2;
  const cy = height - handleRadius * 2;

  // Convert value to angle (180 degrees = π radians)
  const valueToAngle = (val: number): number => {
    const percentage = (10 - val - min) / (max - min);
    return Math.PI * percentage;
  };

  // Convert angle to value and snap to nearest 0.5
  const angleToValue = (angle: number): number => {
    const percentage = angle / Math.PI;
    const rawValue = min + (max - min) * percentage;
    return 10 - Math.round(rawValue / step) * step;
  };

  // Calculate point position on arc
  const getPointFromAngle = (angle: number): { x: number; y: number } => {
    return {
      x: cx + radius * Math.cos(angle),
      y: cy - radius * Math.sin(angle),
    };
  };

  // Handle mouse/touch interactions
  const handleInteraction = useCallback(
    (point: Point) => {
      if (!isDragging || !sliderRef) return;

      const rect = sliderRef.getBoundingClientRect();
      const x = point.clientX - rect.left - cx;
      const y = cy - (point.clientY - rect.top);

      let angle = Math.atan2(y, x);
      angle = Math.max(0, Math.min(Math.PI, angle));

      const newValue = angleToValue(angle);
      onRatingChange?.(newValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDragging, onRatingChange, sliderRef]
  );

  const handleMouseMove = useCallback(
    (event: React.MouseEvent) => {
      handleInteraction(event);
    },
    [handleInteraction]
  );

  const handleTouchMove = useCallback(
    (event: React.TouchEvent) => {
      event.preventDefault();
      const touch = event.touches[0];
      handleInteraction(touch);
    },
    [handleInteraction]
  );

  // Calculate current angle and point position
  const currentAngle = valueToAngle(rating);
  const currentPoint = getPointFromAngle(currentAngle);

  // Create the arc path
  const arcPath = `
    M ${cx - radius} ${cy}
    A ${radius} ${radius} 0 0 1 ${cx + radius} ${cy}
  `;

  const strokeColor =
    rating < 4 ? "#EE6352" : rating < 7 ? "#ffcf70" : "#59cd90";

  return (
    <Box
      backdropBlur="blur(8px)"
      bg="rgba(255, 255, 255, 0.9)"
      backdropFilter="auto"
      borderTopRadius="180px"
      pt="5px"
      pb="20px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="320px"
    >
      <VStack spacing={0}>
        <svg
          ref={setSliderRef}
          width={width}
          height={height}
          className={`touch-none}`}
          onMouseDown={() => setIsDragging(true)}
          onMouseUp={() => setIsDragging(false)}
          onMouseLeave={() => setIsDragging(false)}
          onMouseMove={handleMouseMove}
          onTouchStart={() => setIsDragging(true)}
          onTouchEnd={() => setIsDragging(false)}
          onTouchMove={handleTouchMove}
          style={{ touchAction: "none" }}
        >
          {/* Background arc */}
          <path
            d={arcPath}
            fill="none"
            stroke="#ccc"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
          />

          {/* Filled arc */}
          <path
            d={`
            M ${cx - radius} ${cy}
            A ${radius} ${radius} 0 0 1 ${currentPoint.x} ${currentPoint.y}
          `}
            fill="none"
            stroke={strokeColor}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            style={{ transition: "stroke 0.3s" }}
          />

          {/* Drag handle */}
          <circle
            cx={currentPoint.x}
            cy={currentPoint.y}
            r={strokeWidth * 1.5}
            fill="white"
            stroke={strokeColor}
            strokeWidth="3"
            style={{ cursor: "grab", transition: "stroke 0.3s" }}
          />

          {/* Label */}
          <text
            x={cx}
            y={cy - 10}
            textAnchor="middle"
            style={{
              fontSize: "1.2em",
              fontWeight: "bold",
            }}
            fill="#000"
          >
            {truncateText(itemName, 160)}
          </text>

          {/* Current value */}
          <text
            x={cx}
            y={cy - 50}
            textAnchor="middle"
            style={{ fontSize: "2.5em", fontWeight: "bold" }}
            fill="#000"
          >
            {rating.toFixed(1)}
          </text>
        </svg>
        <HStack>
          <Input
            variant="filled"
            placeholder="Comment"
            value={comment}
            onChange={(e) => onCommentChange(e.target.value)}
          />
          <ActionButton
            icon={FaCheck}
            onClick={onSave}
            padding={2}
            color="black"
            border={"2px solid black"}
          />
        </HStack>
      </VStack>
    </Box>
  );
};

function truncateText(text: string, maxWidth: number): string {
  const measureTextWidth = (text: string) => {
    // Estimate text width based on font size and character width
    const charWidth = 7; // Approximate average width per character (adjust as needed)
    return text.length * charWidth;
  };

  if (measureTextWidth(text) <= maxWidth) {
    return text; // No need to truncate
  }

  let truncated = text;
  while (
    measureTextWidth(truncated + "...") > maxWidth &&
    truncated.length > 0
  ) {
    truncated = truncated.slice(0, -1); // Remove the last character
  }

  return truncated + "..."; // Append ellipsis
}

export default RatingSlider;
