import { Button, Icon } from "@chakra-ui/react";
import React from "react";
import {
  FaBook,
  FaClipboardList,
  FaCompactDisc,
  FaFilm,
  FaHamburger,
  FaMusic,
  FaPodcast,
  FaSpotify,
  FaTv,
  FaUsers,
} from "react-icons/fa";
import { FaBasketball, FaCandyCane, FaCookieBite } from "react-icons/fa6";
import { LuCupSoda } from "react-icons/lu";
import { SiMcdonalds } from "react-icons/si";
import { SlRefresh } from "react-icons/sl";

const ListIcon: React.FC<{ iconName: string; [key: string]: any }> = ({
  iconName,
  ...rest
}) => {
  let iconComponent;
  switch (iconName) {
    case "spotify":
      iconComponent = FaSpotify;
      break;
    case "songs":
      iconComponent = FaMusic;
      break;
    case "artists":
      iconComponent = FaUsers;
      break;
    case "albums":
      iconComponent = FaCompactDisc;
      break;
    case "movies":
      iconComponent = FaFilm;
      break;
    case "shows":
      iconComponent = FaTv;
      break;
    case "podcasts":
      iconComponent = FaPodcast;
      break;
    case "books":
      iconComponent = FaBook;
      break;
    case "fast-food-chains":
      iconComponent = FaHamburger;
      break;
    case "mcdonald's-items":
      iconComponent = SiMcdonalds;
      break;
    case "candy":
      iconComponent = FaCandyCane;
      break;
    case "teams":
      iconComponent = FaBasketball;
      break;
    case "snacks":
      iconComponent = FaCookieBite;
      break;
    case "drinks":
      iconComponent = LuCupSoda;
      break;
    default:
      iconComponent = FaClipboardList;
  }
  return <Icon as={iconComponent} {...rest} />;
};

export const UpdateSpotifyButton: React.FC<{
  textColor?: string;
  onClick: (e: any) => void;
  refreshIconSize?: string;
  spotifyIconSize?: string;
  [key: string]: any;
}> = ({ onClick, refreshIconSize, spotifyIconSize, textColor, ...rest }) => {
  return (
    <Button
      pos="relative"
      padding="0"
      border="none"
      bg="none"
      height={refreshIconSize}
      minW={refreshIconSize}
      onClick={onClick}
      _hover={{ bg: "none" }}
      {...rest}
    >
      <Icon
        as={SlRefresh}
        pos="absolute"
        boxSize={refreshIconSize ?? "36px"}
        color={textColor}
      />
      <Icon
        as={FaSpotify}
        pos="absolute"
        boxSize={spotifyIconSize}
        color={textColor}
      />
    </Button>
  );
};

export default ListIcon;
