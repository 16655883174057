import {
  DndContext,
  KeyboardSensor,
  MeasuringStrategy,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import React, { ReactNode } from "react";
import { ListItem } from "../../../types/list";

interface SortableProps {
  items: any[];
  setItems: (i: any) => void;
  children: ReactNode;
}

export const SortableList: React.FC<SortableProps> = ({
  items,
  setItems,
  children,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const measuringConfig = {
    droppable: {
      strategy: MeasuringStrategy.Always,
    },
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragEnd}
      measuring={measuringConfig}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {children}
      </SortableContext>
    </DndContext>
  );

  function handleDragEnd({ active, over }: any) {
    if (!active || !over || active.id === over.id) {
      return;
    }
    if (active.id !== over.id) {
      setItems((items: ListItem[]) => {
        const oldIndex = items.findIndex(
          (item: ListItem) =>
            (item._id ?? item.title + item.imageURI) === active.id
        );
        const newIndex = items.findIndex(
          (item: ListItem) =>
            (item._id ?? item.title + item.imageURI) === over.id
        );

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }
};
