import {
  Button,
  Heading,
  HStack,
  Icon,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FaList, FaLock, FaUsers } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { BackButton } from "src/components/Buttons";
import { PodiumsWrap } from "src/components/PodiumsCard";
import { UserRows } from "src/components/User/ListOfUsers";
import {
  useCurrentUser,
  useGetAllUsersAdmin,
  useGetWaitlist,
} from "src/state/userQueries";
export enum SelectedPage {
  SelectPage = "SelectPage",
  Waitlist = "Waitlist",
  Users = "Users",
}
const AdminPage = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const currentUserQuery = useCurrentUser();

  useEffect(() => {
    if (currentUserQuery.data && !currentUserQuery.data.isAdmin) {
      toast({
        title: "Unauthorized",
        description: "You must be an admin to access admin pages.",
        status: "error",
        duration: 9000,
      });
      navigate("/");
    }
  }, [currentUserQuery.data, navigate, toast]);
  return (
    <PodiumsWrap>
      <VStack w="100%">
        <HStack width="100%">
          <BackButton to="/me" width="auto" />
          <Heading width="100%" textAlign="center">
            Admin
          </Heading>
        </HStack>
        <Button as={Link} to="/admin/waitlist" width="100%" size="lg">
          <Icon as={FaList} mr="0.5em" />
          View Waitlist
        </Button>
        <Button as={Link} to="/admin/users" width="100%" size="lg">
          <Icon as={FaUsers} mr="0.5em" />
          View Users
        </Button>
      </VStack>
    </PodiumsWrap>
  );
};

export const AdminWaitlistPage = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const currentUserQuery = useCurrentUser();
  const userQuery = useGetWaitlist();

  useEffect(() => {
    if (currentUserQuery.data && !currentUserQuery.data.isAdmin) {
      toast({
        title: "Unauthorized",
        description: "You must be an admin to access admin pages.",
        status: "error",
        duration: 9000,
      });
      navigate("/");
    }
  }, [currentUserQuery.data, navigate, toast]);

  const Inner = () => {
    if (currentUserQuery.isLoading || currentUserQuery.isError) {
      return <Heading>Loading...</Heading>;
    }
    return (
      <VStack width="100%">
        <HStack width="100%">
          <BackButton to="/admin" width="auto" />
          <Heading width="100%" textAlign="center">
            Waitlist ({userQuery.data?.length ?? "..."})
          </Heading>
        </HStack>
        <UserRows
          isLoading={userQuery.isLoading}
          isError={userQuery.isError}
          users={userQuery.data}
          navigate={navigate}
          isAdmin
        />
      </VStack>
    );
  };
  return (
    <PodiumsWrap>
      <Inner />
    </PodiumsWrap>
  );
};

export const AdminUserPage = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [showHourly, setShowHourly] = React.useState(false);

  const currentUserQuery = useCurrentUser();
  const { data, isLoading, isError, error } = useGetAllUsersAdmin();

  useEffect(() => {
    if (currentUserQuery.data && !currentUserQuery.data.isAdmin) {
      toast({
        title: "Unauthorized",
        description: "You must be an admin to access admin pages.",
        status: "error",
        duration: 9000,
      });
      navigate("/");
    }
  }, [currentUserQuery.data, navigate, toast]);

  useEffect(() => {
    if (isError) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 9000,
      });
    }
  });

  const Inner = () => {
    if (currentUserQuery.isLoading || currentUserQuery.isError) {
      return <Heading>Loading...</Heading>;
    }
    return (
      <VStack width="100%">
        <HStack width="100%" pos="relative">
          <BackButton to="/admin" width="auto" pos="absolute" left="10px" />
          <Heading
            width="100%"
            textAlign="center"
            fontSize="28px"
            onClick={() => {
              setShowHourly(!showHourly);
            }}
          >
            <Icon as={FaLock} mr="0.3em" boxSize="0.75em" />
            Admin
          </Heading>
        </HStack>
        <Heading width="100%" textAlign="center" fontSize="20px">
          Total Users: {data?.totalDocs ?? "..."}
        </Heading>
        {showHourly && (
          <>
            <Heading width="100%" textAlign="center" fontSize="1em">
              Last Hour Signups: {data?.lastHourCount ?? "..."}
            </Heading>
            <Heading width="100%" textAlign="center" fontSize="1em">
              Last Hour Updates: {data?.lastHourUpdatesCount ?? "..."}
            </Heading>
          </>
        )}
        <UserRows
          isLoading={isLoading}
          isError={isError}
          users={data?.users}
          navigate={navigate}
          isAdmin
        />
      </VStack>
    );
  };
  return (
    <PodiumsWrap px={4}>
      <Inner />
    </PodiumsWrap>
  );
};

export default AdminPage;
