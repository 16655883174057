import { initializeApp } from "firebase/app";
import React, { ReactNode, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Navigate,
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import { Header } from "./components/Header/Header";
import { ShimmerList } from "./components/Shimmer";
import { UserProfileHeader } from "./components/User/UserProfileHeader";
import AdminPage, { AdminUserPage, AdminWaitlistPage } from "./pages/Admin";
import CallbackPage from "./pages/Callback";
import { CreateList } from "./pages/CreateList";
import { CreatePin } from "./pages/CreatePin";
import HomePage from "./pages/Home";
import ListDiffs from "./pages/ListDiffs";
import ListPage from "./pages/ListPage";
import LoginPage from "./pages/Login";
import MyListDiffs from "./pages/MyActivity";
import OnboardingPage from "./pages/Onboarding";
import ResetPassword from "./pages/ResetPassword";
import SignUpPage from "./pages/SignUp";
import UserPage from "./pages/User";
import { MyFollowers, MyFollowing } from "./pages/UserPages";
import VerifyEmail from "./pages/VerifyEmail";
import { useCurrentUser } from "./state/userQueries";

const firebaseConfig = {
  apiKey: "AIzaSyBUG4jbSfbVfgvgdZD8z9kQaGb1oWRibfA",
  authDomain: "fourth-return-410405.firebaseapp.com",
  projectId: "fourth-return-410405",
  storageBucket: "fourth-return-410405.appspot.com",
  messagingSenderId: "171803009815",
  appId: "1:171803009815:web:d3c42ed8416d5471fe4d52",
  measurementId: "G-GBFB1L1YBD",
};

// Initialize Firebase
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const app = initializeApp(firebaseConfig);

const RedirectToUser = () => {
  const { data, isLoading, isError } = useCurrentUser();
  const user = data;
  const { listSlug } = useParams();
  if (isLoading) {
    return (
      <>
        <Header />
        <UserProfileHeader user={user} />
        <ShimmerList withTitle />
      </>
    );
  } else if (isError || !user) {
    return <Navigate to="/signup" />;
  }

  return <Navigate to={`/${user.username}/${listSlug ?? ""}`} replace />;
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 350);
  }, [pathname]);

  return null;
};

const Page: React.FC<{ title: string; children: ReactNode }> = ({
  title,
  children,
}) => {
  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
};

const App: React.FC = () => {
  return (
    <>
      <Router>
        <ScrollToTop />
        {/* <SignUpSlideUp disclosure={slideUpDisclosure} /> */}
        <div>
          <Routes>
            <Route
              index
              element={
                <Page title="Home — Podiums">
                  <HomePage />
                </Page>
              }
            />
            <Route
              path="activity"
              element={
                <Page title="Activity — Podiums">
                  <ListDiffs />
                </Page>
              }
            />
            <Route path="me" element={<Outlet />}>
              <Route path="" element={<RedirectToUser />} />
              <Route path=":listSlug" element={<RedirectToUser />} />
            </Route>
            <Route path="callback" element={<CallbackPage />} />
            <Route
              path="login"
              element={
                <Page title="Login — Podiums">
                  <LoginPage />
                </Page>
              }
            />
            <Route
              path="signup"
              element={
                <Page title="Sign Up — Podiums">
                  <SignUpPage />
                </Page>
              }
            />
            <Route
              path="onboarding"
              element={
                <Page title="Onboarding — Podiums">
                  <OnboardingPage />
                </Page>
              }
            />
            <Route
              path="verify-email"
              element={
                <Page title="Verify Email — Podiums">
                  <VerifyEmail />
                </Page>
              }
            />
            <Route
              path="reset-password"
              element={
                <Page title="Reset Password — Podiums">
                  <ResetPassword />
                </Page>
              }
            />
            <Route path="create" element={<Outlet />}>
              <Route path="" element={<Navigate to="/create/list" />} />
              <Route
                path="list"
                element={
                  <Page title="Create a list — Podiums">
                    <CreateList />
                  </Page>
                }
              />
              <Route
                path="pin"
                element={
                  <Page title="Create a pin — Podiums">
                    <CreatePin />
                  </Page>
                }
              />
            </Route>
            {/* <Route
              path="explore"
              element={
                <Page title="Explore — Podiums">
                  <ExplorePage />
                </Page>
              }
            /> */}
            <Route path="admin" element={<Outlet />}>
              <Route
                path=""
                element={
                  <Page title="Admin — Podiums">
                    <AdminPage />
                  </Page>
                }
              />
              <Route
                path="waitlist"
                element={
                  <Page title="Admin — Podiums">
                    <AdminWaitlistPage />
                  </Page>
                }
              />
              <Route
                path="users"
                element={
                  <Page title="Admin — Podiums">
                    <AdminUserPage />
                  </Page>
                }
              />
            </Route>
            <Route path=":username" element={<Outlet />}>
              <Route path="" element={<UserPage />} />
              <Route
                path="following"
                element={
                  <Page title="My Following — Podiums">
                    <MyFollowing />
                  </Page>
                }
              />
              <Route
                path="followers"
                element={
                  <Page title="My Followers — Podiums">
                    <MyFollowers />
                  </Page>
                }
              />
              <Route
                path="activity"
                element={
                  <Page title="My Activity — Podiums">
                    <MyListDiffs />
                  </Page>
                }
              />
              <Route path=":listSlug" element={<ListPage />} />
            </Route>
          </Routes>
        </div>
      </Router>
    </>
  );
};

export default App;
