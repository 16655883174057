import {
  Button,
  ButtonGroup,
  Center,
  FormControl,
  HStack,
  Heading,
  Icon,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useFeatureFlagEnabled } from "posthog-js/react";
import React, { Suspense, useEffect, useState } from "react";
import {
  FaArrowDownUpAcrossLine,
  FaArrowRightFromBracket,
  FaCheck,
  FaCircleXmark,
  FaEllipsis,
  FaGear,
  FaRegIdCard,
  FaUser,
} from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import {
  BACKDROP_BLUR,
  BACKGROUND_DARK_GRADIENT_COLORED,
  BLACK_AFTER,
  BLACK_BEFORE,
} from "src/constants";
import {
  useCurrentUser,
  useFollowUser,
  useUnfollowUser,
  useUpdateMe,
} from "src/state/userQueries";
import { SanitizedOtherUser, User } from "src/types/user";
import { getTheme } from "src/types/userThemes";
import { errorToast, useLogout } from "src/utils";
import { ActionButton, BackButton } from "../Buttons";
import PodiumsCard from "../PodiumsCard";
import { ShimmerCircleThumbnail, ShimmerUserProfileHeader } from "../Shimmer";
import EditProfileModal from "./EditProfileModal";
import ProfilePictureUpload from "./UploadProfilePic";
import { UserStats } from "./UserStats";

export const ProfilePicture: React.FC<{
  allowEdit?: boolean;
  src?: string;
  disableClick?: boolean;
  size?: number;
}> = ({ allowEdit, src, disableClick, size }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [largeImage, setLargeImage] = useState<string | undefined>(undefined);
  const toast = useToast();
  const updateMe = useUpdateMe();

  const handleProfilePictureChange = (file: File) => {
    const userPromise = updateMe.mutateAsync({ profilePicture: file });
    toast.promise(userPromise, {
      success: (newUser) => {
        return { title: "Saved!" };
      },
      loading: { title: "Uploading..." },
      error: (error: any) => {
        if (error.response?.status === 400) {
          return { title: "Error", description: error.response.data.message };
        } else {
          // If the request was not successful, handle the error
          if (process.env.REACT_APP_DEBUG) {
            console.error("Error during update profile:", error);
          }
          return { title: "Error saving" };
        }
      },
    });
  };

  const handleImageClick = () => {
    if (!disableClick) {
      setLargeImage(src);
      onOpen();
    }
  };

  return (
    <>
      {allowEdit ? (
        <ProfilePictureUpload
          setProfilePicture={handleProfilePictureChange}
          existingProfilePicture={src}
          size={size ?? 3.5}
        />
      ) : src ? (
        <Image
          boxSize={size ? size + "em" : "3.5em"}
          src={src}
          borderRadius="full"
          transition="all 0.15s ease"
          onClick={handleImageClick}
          fallback={
            <ShimmerCircleThumbnail boxSize={size ? size + "em" : "3.5em"} />
          }
          cursor="pointer"
        />
      ) : (
        <Icon
          as={FaUser}
          bg="topFive.gray.mid"
          color="white"
          border="none"
          boxSize={size ? size + "em" : "3.5em"}
          padding={2}
          borderRadius="full"
          transition="all 0.15s ease"
        />
      )}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay onClick={onClose} backdropFilter="blur(8px)" />
        <ModalContent
          bg="transparent"
          boxShadow="none"
          borderRadius="none"
          p={0}
        >
          <ModalBody>
            <Center h="100%">
              {largeImage && (
                <Image
                  src={largeImage}
                  borderRadius="full"
                  w="80%"
                  objectFit="contain"
                />
              )}
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

interface UserSettingsProps {
  color: string;
  isSingleCol?: boolean;
  onEditLists?: () => void;
  changeBackgroundDisclosure: any;
  editProfileDisclosure: any;
  handleLogout: () => Promise<void>;
  onDisconnectSpotify?: () => void;
  isAdmin?: boolean;
}

interface UserProfileHeaderProps extends Partial<UserSettingsProps> {
  user?: User | SanitizedOtherUser | null;
  onMyProfile?: boolean;
  isLoggedIn?: boolean;
  isReorderingLists?: boolean;
  onSaveLists?: () => void;
  updateThemeLocal?: (theme: string) => void;
  updateLayoutLocal?: (oneColumn: boolean) => void;
  backURL?: string;
}

export const UserProfileHeader: React.FC<UserProfileHeaderProps> = ({
  user,
  onMyProfile,
  isLoggedIn,
  onEditLists,
  isReorderingLists,
  onSaveLists,
  onDisconnectSpotify,
  updateThemeLocal,
  updateLayoutLocal,
  backURL,
}) => {
  const [username, setUsername] = useState(user?.username ?? "");
  const [firstName, setFirstName] = useState(user?.firstName ?? "");
  const [lastName, setLastName] = useState(user?.lastName ?? "");

  const handleLogout = useLogout();
  const toast = useToast();
  const changeThemeDisclosure = useDisclosure();
  const editProfileDisclosure = useDisclosure();
  const navigate = useNavigate();
  const updateMe = useUpdateMe();
  const allowFollow = useFeatureFlagEnabled("allowFollow");
  const showUserStats = useFeatureFlagEnabled("showUserStats");

  const handleSubmit = async () => {
    if (
      !user ||
      (user.username === username &&
        user.firstName === firstName &&
        user.lastName === lastName)
    ) {
      // No changes made
      editProfileDisclosure.onClose();
      return;
    }
    const userPromise = updateMe.mutateAsync({
      username: user.username !== username ? username : undefined,
      firstName,
      lastName,
    });
    toast.promise(userPromise, {
      success: (newU) => {
        editProfileDisclosure.onClose();
        navigate(`/${username}/`, { replace: true });
        return { title: "Saved!" };
      },
      error: (error: any) => {
        setUsername(user.username);
        setFirstName(user.firstName ?? "");
        setLastName(user.lastName ?? "");
        if (error.response?.status === 400) {
          return { title: "Error", description: error.response.data.message };
        } else {
          // If the request was not successful, handle the error
          if (process.env.REACT_APP_DEBUG) {
            console.error("Error during update profile:", error);
          }
          return { title: "Error saving" };
        }
      },
      loading: { title: "Loading..." },
    });
  };

  if (!user) {
    return <ShimmerUserProfileHeader />;
  }

  const theme = getTheme(user.backgroundType);

  return (
    <Center px={3} width="100%">
      <Suspense fallback={<ShimmerUserProfileHeader />}>
        <PodiumsCard
          transition="all 0.15s ease-in-out"
          bg={
            theme.name === "Black"
              ? BACKGROUND_DARK_GRADIENT_COLORED
              : theme.cardBg
          }
          borderWidth={theme.cardBorderColor !== "none" ? "1px" : 0}
          borderColor={theme.cardBorderColor}
          boxShadow={theme.cardShadow ?? "md"}
          zIndex={1}
          backdropFilter="auto"
          backdropBlur={BACKDROP_BLUR}
          boxSizing="border-box"
          _before={theme.name === "Black" ? BLACK_BEFORE : undefined}
          _after={theme.name === "Black" ? BLACK_AFTER : undefined}
        >
          <VStack align="start" width="100%" spacing="1em" zIndex={1}>
            <HStack align="center" width="100%" justify="space-between">
              <HStack spacing={3} width="100%">
                {backURL && (
                  <BackButton
                    to={backURL}
                    fontSize="1.25em"
                    width="auto"
                    color={theme.textColor}
                  />
                )}
                <ProfilePicture
                  src={user.profilePicture}
                  size={3.5}
                />
                <VStack align="left" spacing={0}>
                  <Heading
                    fontSize={"1.15em"}
                    marginBottom={0.5}
                    color={theme.textColor}
                  >
                    {user.firstName + " " + user.lastName}
                  </Heading>
                  <Heading size="sm" fontWeight="500" color={theme.textColor}>
                    @{user.username}
                  </Heading>
                </VStack>
              </HStack>
              {onMyProfile ? (
                <HStack spacing={2}>
                  {isReorderingLists ? (
                    <ActionButton
                      icon={FaCheck}
                      onClick={onSaveLists}
                      p={2}
                      mx={1}
                      color="black"
                      border={"2px solid black"}
                    />
                  ) : (
                    <UserSettings
                      color={theme.textColor}
                      onEditLists={onEditLists}
                      changeBackgroundDisclosure={changeThemeDisclosure}
                      editProfileDisclosure={editProfileDisclosure}
                      handleLogout={handleLogout}
                      onDisconnectSpotify={onDisconnectSpotify}
                      isAdmin={(user as User).isAdmin}
                      isSingleCol={(user as User).listLayout.length === 1}
                    />
                  )}
                </HStack>
              ) : (
                isLoggedIn && allowFollow && <FollowButtons user={user} />
              )}
            </HStack>
            {editProfileDisclosure.isOpen && (
              <>
                <VStack py="0.5em" spacing={4} width="100%">
                  <FormControl>
                    <InputGroup>
                      <InputLeftAddon
                        bg="#ffffff"
                        borderRight="none"
                        pr={1}
                        color="gray.500"
                      >
                        podiu.ms/
                      </InputLeftAddon>
                      <Input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Username"
                        required
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="none"
                        spellCheck="false"
                        borderLeft="none"
                        pl={0.5}
                      />
                    </InputGroup>
                  </FormControl>
                  <FormControl>
                    <Input
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="First name"
                      required
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="none"
                      spellCheck="false"
                    />
                  </FormControl>
                  <FormControl>
                    <Input
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Last name"
                      required
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="none"
                      spellCheck="false"
                    />
                  </FormControl>
                </VStack>

                <ButtonGroup mx="auto">
                  <Button variant="dark" onClick={handleSubmit}>
                    Save
                  </Button>
                  <Button onClick={editProfileDisclosure.onClose}>
                    Cancel
                  </Button>
                </ButtonGroup>
              </>
            )}
            {showUserStats && onMyProfile && user.lists.length > 0 && (
              <UserStats user={user as User} />
            )}
            <EditProfileModal
              user={user}
              disclosure={changeThemeDisclosure}
              setLocalOneColumn={updateLayoutLocal ?? ((b) => {})}
              setLocalTheme={updateThemeLocal ?? ((t) => {})}
            />
          </VStack>
        </PodiumsCard>
      </Suspense>
    </Center>
  );
};

const FollowButtons: React.FC<{ user: SanitizedOtherUser }> = ({ user }) => {
  const toast = useToast();
  const { data: currentUser, isLoading: isUserLoading } = useCurrentUser();
  const followUser = useFollowUser();
  const unfollowUser = useUnfollowUser();
  const [isFollowing, setIsFollowing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!!currentUser) {
      setIsFollowing(currentUser.following.find((u) => u === user._id));
    }
  }, [currentUser, user._id]);

  const followUserToast = async () => {
    try {
      setIsLoading(true);
      await followUser.mutateAsync(user.username ?? "");
    } catch (e) {
      errorToast("Try again", toast);
    } finally {
      setIsLoading(false);
    }
  };

  const unfollowUserToast = async () => {
    try {
      setIsLoading(true);
      await unfollowUser.mutateAsync(user.username ?? "");
      setIsLoading(false);
    } catch (e) {
      errorToast("Try again", toast);
    } finally {
      setIsLoading(false);
    }
  };

  if (isUserLoading) {
    return <Button>Loading</Button>;
  } else if (isFollowing) {
    return (
      <Button
        onClick={unfollowUserToast}
        variant="dark"
        px="1.5em"
        isLoading={isLoading}
        loadingText="Follow"
        spinnerPlacement="end"
      >
        Following
      </Button>
    );
  } else {
    return (
      <Button
        onClick={followUserToast}
        px="1.5em"
        isLoading={isLoading}
        loadingText="Follow"
        spinnerPlacement="end"
      >
        Follow
      </Button>
    );
  }
};

const UserSettings: React.FC<UserSettingsProps> = ({
  color,
  onEditLists,
  changeBackgroundDisclosure,
  editProfileDisclosure,
  handleLogout,
  onDisconnectSpotify,
  isAdmin,
  isSingleCol,
}) => {
  return (
    <Menu>
      <MenuButton
        aria-label="Settings"
        bg="none"
        p={0}
        color={color}
        fontSize="1.5em"
        as={IconButton}
        icon={<FaEllipsis />}
        _hover={{ bg: "none", color: color }}
        _active={{ bg: "none", color: color }}
      />
      <MenuList boxShadow="0px 8px 20px 0px rgba(0,0,0,0.2)" fontSize="1.1em">
        <MenuItem
          icon={<FaRegIdCard size="1.5em" />}
          onClick={changeBackgroundDisclosure.onOpen}
        >
          Edit profile
        </MenuItem>
        <MenuItem
          icon={<FaArrowDownUpAcrossLine size="1.5em" />}
          onClick={onEditLists}
        >
          Edit lists
        </MenuItem>
        {/* <MenuItem icon={<FaBolt />} as={RouterLink} to={"/me/activity"}>
          My Activity
        </MenuItem> */}
        {onDisconnectSpotify && (
          <MenuItem
            icon={
              <FaCircleXmark size="1.25em" style={{ margin: "0 0.125em" }} />
            }
            onClick={onDisconnectSpotify}
          >
            Disconnect Spotify
          </MenuItem>
        )}
        {isAdmin && (
          <MenuItem
            icon={<FaGear size="1.25em" style={{ margin: "0 0.125em" }} />}
            as={Link}
            to="/admin"
          >
            Admin page
          </MenuItem>
        )}
        <MenuItem
          icon={
            <FaArrowRightFromBracket
              size="1.25em"
              style={{ margin: "0 0.125em" }}
            />
          }
          onClick={handleLogout}
        >
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
