import { Center, Heading, VStack } from "@chakra-ui/react";
import { UseQueryResult } from "@tanstack/react-query";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "src/components/Header/Header";
import PodiumsCard from "src/components/PodiumsCard";
import { ShimmerUserSearchRow } from "src/components/Shimmer";
import { UserProfileHeader } from "src/components/User/UserProfileHeader";
import UserSearchRow from "src/components/User/UserSearchRow";
import { useCurrentUser } from "src/state/userQueries";
import { UserForAdmin } from "src/types/user";
import GradientBackground from "./UserBackground";

const ListOfUsers: React.FC<{
  query: UseQueryResult<UserForAdmin[], Error>;
  title?: string;
}> = ({ query, title }) => {
  const { data: currentUser } = useCurrentUser();
  const navigate = useNavigate();

  const { isLoading, isError, data: users } = query;

  return (
    <GradientBackground themeSlug={currentUser?.backgroundType}>
      <Header />
      <UserProfileHeader
        user={currentUser}
        backURL={`/${currentUser?.username}`}
        isLoggedIn
        onMyProfile
      />
      <Center px={3} pb="1em">
        <VStack width="100%" maxW="450px" spacing={3}>
          <Heading color="white" whiteSpace="nowrap">
            {title && title}
          </Heading>
          <UserRows
            isLoading={isLoading}
            isError={isError}
            users={users}
            navigate={navigate}
          />
        </VStack>
      </Center>
    </GradientBackground>
  );
};

export const UserRows: React.FC<{
  isLoading: boolean;
  isError: boolean;
  users: UserForAdmin[] | undefined;
  navigate: (to: string, params: any) => void;
  isAdmin?: boolean;
}> = ({ isLoading, isError, users, navigate, isAdmin }) => {
  if (isLoading) {
    return (
      <>
        {[...Array(10)].map((_, index) => (
          <ShimmerUserSearchRow key={index} />
        ))}
      </>
    );
  } else if (isError || !users || users.length === 0) {
    return (
      <>
        <PodiumsCard>
          <Heading size="4xl">😥</Heading>
          <Heading>No users</Heading>
        </PodiumsCard>
      </>
    );
  } else {
    return (
      <VStack w="100%" spacing={2} mt="0.3em">
        {users.map((u) => (
          <UserSearchRow
            user={u}
            key={u._id}
            onClick={() => {
              navigate(`/${u.username}`, {
                state: { backURL: window.location.href },
              });
            }}
            isAdmin={isAdmin}
          />
        ))}
      </VStack>
    );
  }
};

export default ListOfUsers;
