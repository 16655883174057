import { Box } from "@chakra-ui/react";
import React from "react";
import { DeviceMockup } from "src/pages/Home";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { Autoplay, EffectCards } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const ScreenshotSlider = () => {
  return (
    <Box maxW={350}>
      <Swiper
        modules={[Autoplay, EffectCards]}
        speed={500}
        effect="cards"
        autoplay={{ delay: 2500, disableOnInteraction: false }}
        cardsEffect={{ slideShadows: false }}
        slidesPerView={1}
        width={350}
        style={{ overflow: "visible" }}
      >
        <SwiperSlide>
          <DeviceMockup imageURL="/screenshots/janelle-profile-sage.png" />
        </SwiperSlide>
        <SwiperSlide>
          <DeviceMockup imageURL="/screenshots/noah-profile.png" />
        </SwiperSlide>
        <SwiperSlide>
          <DeviceMockup imageURL="/screenshots/carter-profile-white.png" />
        </SwiperSlide>
      </Swiper>
    </Box>
  );
};

export default ScreenshotSlider;
