import { Center, Heading, useDisclosure, useToast } from "@chakra-ui/react";
import { useFeatureFlagEnabled } from "posthog-js/react";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FaCircleXmark } from "react-icons/fa6";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Header } from "src/components/Header/Header";
import EditListsModal from "src/components/Lists/ListOfLists/EditListsModal";
import ListOfLists from "src/components/Lists/ListOfLists/ListOfLists";
import { ShimmerUser } from "src/components/Shimmer";
import { FloatingCreateButton } from "src/components/User/FloatingCreateButton";
import HeyYoureNew from "src/components/User/HeyYoureNew";
import GradientBackground from "src/components/User/UserBackground";
import { UserProfileHeader } from "src/components/User/UserProfileHeader";
import {
  useCurrentUser,
  useDisconnectSpotify,
  useGetUser,
} from "src/state/userQueries";
import { getTheme } from "src/types/userThemes";
import "../backgrounds.css";
import { UserPins } from "../components/Pins/Pins";
import PodiumsCard from "../components/PodiumsCard";
import { PodiumsDialog } from "../components/PodiumsDialog";

const UserPage: React.FC = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const disconnectSpotifyDisclosure = useDisclosure();
  const editListsDisclosure = useDisclosure();
  const [isSingleCol, setIsSingleCol] = useState<boolean | undefined>(
    undefined
  );
  const [showSpotifyOnboarding, setShowSpotifyOnboarding] = useState(false);
  const toast = useToast();

  const pinsEnabled = useFeatureFlagEnabled("pinsEnabled");

  const {
    data: currentUser,
    isLoading: curUserLoading,
    isError: curUserError,
  } = useCurrentUser();
  const onMyProfile =
    curUserLoading || (!!currentUser && currentUser.username === username);
  const {
    data: getUserData,
    isError: getUserError,
    isLoading: getUserLoading,
  } = useGetUser(username ?? "", !onMyProfile);

  const disconnectSpotify = useDisconnectSpotify();
  const location = useLocation();
  const backURL = location.state?.backURL;

  const user = onMyProfile ? currentUser! : getUserData;
  const [themeSlug, setTheme] = useState<string | undefined>(
    user?.backgroundType
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const isSpotify = queryParams.get("spotify");
    if (isSpotify) {
      setShowSpotifyOnboarding(true);
    }
  }, []);

  useEffect(() => {
    if (!user) return;
    setTheme(user?.backgroundType);
    setIsSingleCol(user?.listLayout.length === 1);
  }, [user]);

  const onDisconnectSpotify = () => {
    toast.promise(disconnectSpotify.mutateAsync(), {
      success: { title: "Spotify disconnected" },
      loading: { title: "Loading..." },
      error(err) {
        return {
          title: "Error",
          description: err.message,
        };
      },
    });
    navigate("/me");
  };

  useEffect(() => {
    if (username !== username?.toLowerCase()) {
      navigate(`/${username?.toLowerCase()}`);
      return;
    }
  }, [username, user, navigate, curUserError, getUserError]);

  if (getUserLoading || curUserLoading) {
    return (
      <>
        <Helmet>
          <title>Podiums — @{username}</title>
        </Helmet>
        <ShimmerUser />
      </>
    );
  } else if (getUserError || !user) {
    return (
      <>
        <Helmet>
          <title>Podiums — @{username}</title>
        </Helmet>
        <GradientBackground>
          <Header />
          <Center width="100%" px={3}>
            <PodiumsCard>
              <Heading size="4xl">😥</Heading>
              <Heading>User not found</Heading>
            </PodiumsCard>
          </Center>
        </GradientBackground>
      </>
    );
  } else {
    const hasSpotify =
      !!onMyProfile && currentUser
        ? currentUser.oauthIntegrations.find(
            (i) => i.providerSlug === "spotify"
          )
        : false;
    const theme = getTheme(themeSlug);
    return (
      <>
        <Helmet>
          <title>Podiums — @{username}</title>
        </Helmet>
        <GradientBackground themeSlug={themeSlug}>
          <Header theme={theme} />
          <UserProfileHeader
            user={user}
            isLoggedIn={!!currentUser}
            onMyProfile={onMyProfile}
            onEditLists={editListsDisclosure.onOpen}
            onDisconnectSpotify={
              hasSpotify ? disconnectSpotifyDisclosure.onOpen : undefined
            }
            updateThemeLocal={setTheme}
            updateLayoutLocal={setIsSingleCol}
            backURL={backURL}
          />
          {pinsEnabled && (
            <UserPins
              items={user?.pins ?? []}
              onMyProfile={onMyProfile}
              username={username ?? ""}
            />
          )}
          {showSpotifyOnboarding && onMyProfile && (
            <HeyYoureNew
              theme={theme}
              dismiss={() => setShowSpotifyOnboarding(false)}
            />
          )}
          <ListOfLists
            user={user}
            onMyProfile={onMyProfile}
            localIsSingleColumn={isSingleCol}
            editListsDisclosure={editListsDisclosure}
          />
          {onMyProfile && user && (
            <FloatingCreateButton
              isFirstList={user.lists.length === 0 || showSpotifyOnboarding}
            />
          )}
        </GradientBackground>
        <PodiumsDialog
          disclosure={disconnectSpotifyDisclosure}
          title="Disconnect Spotify"
          message="Are you sure you want to disconnect Spotify? This will remove your recommendations on Podiums, and stop auto-syncing any connected lists."
          buttons={[
            {
              text: "Disconnect",
              onClick: onDisconnectSpotify,
              colorScheme: "red",
              icon: FaCircleXmark,
            },
          ]}
          cancelText="Cancel"
        />
        <EditListsModal user={user} disclosure={editListsDisclosure} />
      </>
    );
  }
};

export default UserPage;
